import React, { useEffect, useState } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router';
import { changeDateTimeFormat, checkFeatures, checkPermission } from '../../../utils/helpers';
import { MoreMenuIconHorizontal } from '../../../shared/component/svgIcon';
import DropDown from '../../../shared/component/DropDown';
import {
  fetchSavedContentLayout,
  updateSavedLayout,
  deleteSavedLayout as deleteSavedLayoutReducer,
} from '../../../redux/slices/SavedContentLayoutSlice';
import wejhaImage from '../../../assets/images/loginPage.jpg';
import contentLayout from '../../../utils/api/content-layout';
import height from '../../../utils/size-variables';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import DeleteModal from '../../../shared/component/DeleteModal';

function SavedLayouts() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    savedContentLayout,
    status,
    pageCount,
    totalPageCount,
    filter,
  } = useSelector((state) => state.savedContentLayout);
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedIdToDelete, setSelectedIdToDelete] = useState('');

  const viewSavedLayout = (id) => {
    navigate(`../edit/${id}`);
  };

  const editSavedLayoutName = (id, name) => {
    contentLayout.updateSavedLayout(id, { name })
      .then((res) => {
        dispatch(updateSavedLayout(res?.data));
      })
      .catch((err) => {
        setErrorNotification(err?.response?.data);
      });
  };

  const deleteSavedLayout = (id) => {
    setSelectedIdToDelete(id);
    setOpenDeleteModal(true);
  };

  const actions = [
    {
      name: t('view'),
      url: '',
      function: viewSavedLayout,
      id: 'view-content',
      disabled: !checkFeatures('layouts') || !checkPermission('getLayouts'),
    },
    {
      name: t('delete'),
      url: '',
      function: deleteSavedLayout,
      id: 'delete-content',
      labelColor: '#E8212E',
      disabled: !checkFeatures('layouts') || !checkPermission('deleteLayouts'),
    },
  ];

  const getSavedLayout = (page, filteredValues = {}) => {
    dispatch(fetchSavedContentLayout({ page, ...filteredValues }));
  };

  const handleScroll = () => {
    if (pageCount < totalPageCount) {
      getSavedLayout(pageCount + 1, filter);
    }
  };

  const handleDeleteById = () => {
    contentLayout.deleteSavedContentLayout(selectedIdToDelete)
      .then(() => {
        dispatch(deleteSavedLayoutReducer(selectedIdToDelete));
        setOpenDeleteModal(false);
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  useEffect(() => {
    if (pageCount === 0) {
      getSavedLayout(1);
    }
  }, []);

  return (
    <div>
      <DeleteModal
        status={openDeleteModal}
        closeModal={setOpenDeleteModal}
        okFunction={handleDeleteById}
        buttonLabel="Delete"
        title={t('confirmation')}
        subTitle={t('areYouSureWantToDeleteThisFile')}
      />
      <InfiniteScroll
        dataLength={savedContentLayout.length}
        next={handleScroll}
        height={height.cardsHeight}
        hasMore={pageCount < totalPageCount}
        loader={status === 'loading' && (
          <div className="content-layout-loader-wrap">
            <CircularProgress color="success" />
          </div>
        )}
      >
        {(status === 'loading' && pageCount === 0)
          && (
            <div className="content-layout-loader-wrap">
              <CircularProgress color="success" />
            </div>
          )}
        <div className="content-layout-saved-layout-wrap">
          {savedContentLayout?.map((layout) => (
            <div className="single-layout-wrap">
              <div className="layout-image-wrap">
                {layout?.signedUrl
                  ? (
                    <img src={layout?.signedUrl || wejhaImage} alt="" className="layout-image" />
                  ) : (
                    <div className="card">
                      <div className="shimmerBG media"> </div>
                    </div>
                  )}
              </div>
              <div className="layout-details">
                <div className="layout-header">
                  <div className="layout-header-details">
                    <div className="layout-header-avatar">
                      <span>{layout?.name?.charAt(0)}</span>
                    </div>
                    <h1>{layout?.name}</h1>
                  </div>
                  <div
                    role="presentation"
                    onClick={() => { }}
                    className="layout-options"
                  >
                    <DropDown
                      displayEditIcon={checkFeatures('layouts')}
                      onClickEditIcon={editSavedLayoutName}
                      value={layout}
                      action={actions}
                      icon={<MoreMenuIconHorizontal />}
                    />
                  </div>
                </div>
                <p>{changeDateTimeFormat(layout?.updatedAt)}</p>
              </div>
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
}

export default SavedLayouts;
