import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import PropTypes from 'prop-types';
// Material-UI Imports
import { Drawer, IconButton } from '@mui/material';

import logo from '../../../assets/images/logo.png';
import profileImage from '../../../assets/images/download.jpg';
import notificationIcon from '../../../assets/icons/notification.png';

import Popover from '../PopOver';
import Notification from './Notification';
import DropDownMenu from '../DropDownMenu';
import {
  ArrowDownIcon,
  WorldIcon,
  CloseIcon,
  HamburgerIcon,
} from '../svgIcon';
import auth from '../../../utils/api/auth';
import notification from '../../../utils/api/notification';
import {
  capitalize,
  checkFeatures,
  checkPermission,
  getCookieStorageItem,
  removeCookieStorageItem,
} from '../../../utils/helpers';
import { resetAllSlices } from '../../../redux/slices/ReduxStateResetSlice';

function Header(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const currentLanguageCode = cookies.get('i18next') || 'en';
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationCount, setNotificationCount] = useState(null);
  const [focused, setFocused] = useState(true); // Assuming initially the screen is focused
  const [visible, setVisible] = useState(true); // Initially assume the page is visible
  const [drawerOpen, setDrawerOpen] = useState(false); // State to manage drawer open/close

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const logout = () => {
    const data = getCookieStorageItem('secure-auth-refresh');
    if (data) {
      auth.logout().then((response) => {
        if (response.status === 204) {
          removeCookieStorageItem('secure-auth-access');
          removeCookieStorageItem('secure-auth-refresh');
          localStorage.removeItem('data');
          localStorage.removeItem('role');
          localStorage.removeItem('features');
          localStorage.removeItem('walkthroughCompleted');
          // localStorage.removeItem('showSubscriptionPrompt');
          navigate('/login');
          dispatch(resetAllSlices());
        }
      }).catch((error) => {
        console.log(error);
      });
    } else {
      navigate('/login');
      dispatch(resetAllSlices());
    }
  };

  const handleCloseNotification = () => {
    setAnchorEl(null);
    setNotificationCount(null);
  };

  const handleRestartWalkthrough = () => {
    props?.setStepIndex(0);
    props?.setSideBarMenu((prevValue) => prevValue.map((menu) => ({
      ...menu,
      accordion: false,
    })));
    props?.setRunWalkthrough(true);
  };

  const userProfile = {
    name: user?.name,
    url: '',
    function: () => { },
    key: 'user-name',
    disabled: !checkFeatures('preference', 'view')
      || !checkPermission('getPreference'),
    icon: user?.signedUrl || profileImage,
    email: user?.email,
  };

  const actions = [
    {
      name: t('settings'),
      url: '',
      function: () => navigate('/admin/settings'),
      key: 'settings',
      disabled: false,
    },
    // {
    //   name: t('subscription'),
    //   url: '',
    //   function: () => navigate('/admin/subscription'),
    //   key: 'subscription',
    //   disabled: !checkFeatures('subscription', 'view')
    //     || (!checkPermission('getSubscription') && !checkPermission('getTransaction')),
    // },
    {
      name: t('walkthrough'),
      url: '',
      function: handleRestartWalkthrough,
      key: 'subscription',
      disabled: false,
    },
    {
      name: t('logout'),
      url: '',
      function: logout,
      disabled: false,
      key: 'logout',
    },
  ];

  const handleLanguage = (code) => {
    i18next.changeLanguage(code);
    window.location.reload();
  };

  const menuList = [
    {
      code: 'en',
      name: 'English',
      function: handleLanguage,
      disabled: currentLanguageCode === 'en',
    },
    {
      code: 'ar',
      name: 'عربي (Arabic)',
      function: handleLanguage,
      disabled: currentLanguageCode === 'ar',
    },
  ];

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('data'));
    if (!data) {
      navigate('/login');
      dispatch(resetAllSlices());
    } else {
      setUser(data.user);
    }
  }, [navigate]);

  useEffect(() => {
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);
    const onVisibilityChange = () => setVisible(!document.hidden);

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId;

    const fetchData = () => {
      // Make an API call here
      notification.getNotificationCount()
        .then((res) => setNotificationCount(res?.data?.count))
        .catch(() => { });
    };

    const startInterval = () => {
      fetchData(); // Initial API call
      intervalId = setInterval(fetchData, 1 * 60 * 1000); // Call every 2 minutes
    };

    const stopInterval = () => {
      clearInterval(intervalId);
    };

    // Only start or stop the interval if both focused and visible
    if (focused && visible) {
      startInterval();
    } else {
      stopInterval();
    }

    return () => {
      stopInterval(); // Cleanup interval on component unmount
    };
  }, [focused, visible]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <div className="header">
      <div className="hamburger-icon">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => props?.setDrawerOpen(true)}
        >
          <HamburgerIcon />
        </IconButton>
      </div>
      <div className="left-navbar">
        <img src={logo} alt="logo" />
      </div>
      <div className="right-navbar">
        <div className="language-wrap">
          <p>{currentLanguageCode === 'en' ? 'English' : 'عربي '}</p>
          <DropDownMenu
            dropdown="dropdown"
            icon={<WorldIcon />}
            action={menuList}
            tooltip={t('language')}
          />
        </div>
        <div className="notification-icon">
          <img
            role="presentation"
            onClick={handleClick}
            aria-describedby="notification-popover"
            src={notificationIcon}
            alt="notification"
          />
          {notificationCount > 0 && (
            <div className="notification-count">
              <span>{notificationCount}</span>
            </div>
          )}
          <Popover
            id="notification-popover"
            anchorEl={anchorEl}
            handleClose={handleCloseNotification}
            content={<Notification />}
          />
        </div>
        <div className="profile-button">
          <div className="avatar-image">
            {user?.signedUrl ? (
              <img src={user?.signedUrl || profileImage} alt="avatarimage" />
            ) : (
              <div
                className="profile-wrap"
                style={{ '--profile-color': '#30A84B' }}
              >
                {user?.name && <span>{capitalize(user?.name?.charAt(0))}</span>}
              </div>
            )}
          </div>
          <div className="user-name">
            <span className="name" htmlFor="Super Admin">{user?.name}</span>
            <span className="role"> {user?.user_name}</span>
          </div>
          <div className="dropdown-arrow-icon">
            <DropDownMenu
              dropdown="dropdown"
              action={actions}
              icon={<ArrowDownIcon />}
              userProfile={userProfile}
              customPadding
            />
          </div>
        </div>
      </div>
      {/* Hamburger Icon */}
      {/* Drawer */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        className="sidebar-drawer"
      >
        <div className="drawer-content">
          <div className="drawer-header">
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon color="#000" />
            </IconButton>
          </div>
          <div className="drawer-menu">
            {/* Your sidebar content here */}
          </div>

        </div>
      </Drawer>
    </div>
  );
}

Header.propTypes = {
  setStepIndex: PropTypes.func.isRequired,
  setRunWalkthrough: PropTypes.func.isRequired,
  setSideBarMenu: PropTypes.func.isRequired,
  setDrawerOpen: PropTypes.func.isRequired,
};

export default Header;
