/* eslint no-underscore-dangle: 0 */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { LayoutEditIcon } from '../../../shared/component/svgIcon';
import { fetchAvailableContentLayout } from '../../../redux/slices/ContentLayoutSlice';
import { checkFeatures, checkPermission } from '../../../utils/helpers';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function AvailableLayouts() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentLanguageCode = cookies.get('i18next');
  const { availableContentLayout, status } = useSelector((state) => state.contentLayout);

  const eachLayoutClassName = (columnEnd) => {
    let className;
    if (currentLanguageCode === 'en' && columnEnd === 13) {
      className = 'each-layout no-right-border';
    } else if (currentLanguageCode === 'en') {
      className = 'each-layout';
    } else if (currentLanguageCode === 'ar' && columnEnd === 13) {
      className = 'each-layout-ar no-left-border';
    } else if (currentLanguageCode === 'ar') {
      className = 'each-layout-ar';
    }
    return className;
  };

  const handleLayoutAdd = (id) => {
    if (checkPermission('addLayouts') && checkFeatures('layouts')) {
      navigate(`/admin/content-layout/add/${id}`);
    } else {
      dispatch(setErrorNotification({ message: 'Permission Denied!' }));
    }
  };

  useEffect(() => {
    if (availableContentLayout?.length === 0) {
      dispatch(fetchAvailableContentLayout());
    }
  }, []);

  return (
    <div className="available-content-layout-wrap">
      {status === 'loading'
        ? (
          <div className="content-layout-loader-wrap">
            <CircularProgress color="success" />
          </div>
        ) : (
          <div>
            <div className="available-layout-type-wrap">
              <h1>{t('gridLayoutLandscape')}</h1>
              <div className="available-layout-type">
                {availableContentLayout?.map((availableLayout) => availableLayout?.orientation === 'landscape' && (
                  <div
                    className="single-layout-wrap"
                    role="presentation"
                    onClick={() => handleLayoutAdd(availableLayout?._id)}
                  >
                    <div className="single-layout-landscape">
                      <div className="single-layout-grid">
                        {availableLayout?.layouts?.map((layout) => (
                          <div
                            className={eachLayoutClassName(layout?.grid_column_end)}
                            style={{
                              gridColumnStart: layout?.grid_column_start,
                              gridColumnEnd: layout?.grid_column_end,
                              gridRowStart: layout?.grid_row_start,
                              gridRowEnd: layout?.grid_row_end,
                            }}
                          />
                        ))}
                        <div className="use-layout-wrap">
                          <span>
                            <LayoutEditIcon
                              height="20px"
                              width="20px"
                              color="#FFFFFF"
                            />
                          </span>
                          <p>{t('useLayout')}</p>
                        </div>
                      </div>
                    </div>
                    <h2>{availableLayout?.name}</h2>
                  </div>
                ))}
              </div>
            </div>
            <div className="available-layout-type-wrap">
              <h1>{t('gridLayoutPortrait')}</h1>
              <div className="available-layout-type">
                {availableContentLayout?.map((availableLayout) => availableLayout?.orientation === 'portrait' && (
                  <div
                    className="single-layout-wrap"
                    role="presentation"
                    onClick={() => handleLayoutAdd(availableLayout?.id)}
                  >
                    <div className="single-layout-portrait">
                      <div className="single-layout-grid">
                        {availableLayout?.layouts?.map((layout) => (
                          <div
                            className={eachLayoutClassName(layout?.grid_column_end)}
                            style={{
                              gridColumnStart: layout?.grid_column_start,
                              gridColumnEnd: layout?.grid_column_end,
                              gridRowStart: layout?.grid_row_start,
                              gridRowEnd: layout?.grid_row_end,
                            }}
                          />
                        ))}
                        <div className="use-layout-wrap">
                          <span>
                            <LayoutEditIcon
                              height="20px"
                              width="20px"
                              color="#FFFFFF"
                            />
                          </span>
                          <p>{t('useLayout')}</p>
                        </div>
                      </div>
                    </div>
                    <h2>{availableLayout?.name}</h2>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
    </div>
  );
}

export default AvailableLayouts;
