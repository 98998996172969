import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { nameValidation, passwordValidation } from '../../../shared/component/form/Validation';
import { Input } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import auth from '../../../utils/api/auth';
import {
  setCookieStorageItem,
}
  from '../../../utils/helpers';

function UserForm(props) {
  const { t } = useTranslation();
  const [disable, setDisable] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [uploading, setUploading] = useState(false);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const handleChange = (name, changeValue) => {
    props?.setFormData({ ...props?.formData, [name]: changeValue });
  };
  const nextStage = async () => {
    setSubmitButtonClicked(true);
    setUploading(true);
    try {
      if (props?.formData?.password === props?.formData?.re_password
        && (nameValidation(1, 50, props?.formData?.user_full_name)
          && nameValidation(1, 50, props?.formData?.user_name)
          && passwordValidation(props?.formData?.password)
          && passwordValidation(props?.formData?.re_password)
        )) {
        const userData = {
          email: props?.formData?.email,
          password: props?.formData?.password,
          name: props?.formData?.user_full_name,
          user_name: props?.formData?.user_name,
        };
        const token = props?.formData?.registerToken;
        if (token) {
          auth.selfRegister(userData, token)
            .then((response) => {
              setCookieStorageItem('secure-auth-access', response?.data?.tokens?.access?.token);
              setCookieStorageItem('secure-auth-refresh', response?.data?.tokens?.refresh?.token);
              setUploading(false);
              props?.setFormStage(3);
            })
            .catch((err) => {
              setUploading(false);
              setDisable(true);
              setErrorMsg(err?.response?.data?.message);
            });
        } else {
          setErrorMsg('Unauthorized!');
          setTimeout(() => {
            props?.setFormStage(1);
          }, 3000);
        }
      } else {
        setUploading(false);
        setDisable(true);
        // setErrorMsg(t('passwordMismatch'));
      }
    } catch (err) {
      setErrorMsg(err);
    }
    // else if (props?.formData?.password?.length < 8) {
    // setUploading(false);
    // setDisable(true);
    // setErrorMsg(t('passwordLengthRequirement'));
    // }
  };

  useEffect(() => {
    if (
      props?.formData?.user_full_name
      && props?.formData?.user_name
      && props?.formData?.password
      && props?.formData?.re_password
    ) {
      setErrorMsg('');
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [props?.formData]);

  return (
    <div className="user-register-wrap">
      <div className="single-row">
        <Input
          type="text"
          label={t('fullName')}
          name="user_full_name"
          value={props?.formData?.user_full_name}
          change={handleChange}
          error={!nameValidation(1, 50, props?.formData?.user_full_name) && submitButtonClicked}
          errorMessage={t('nameValidation')}
        />
        <Input
          type="text"
          label={t('userName')}
          name="user_name"
          value={props?.formData?.user_name}
          change={handleChange}
          error={!nameValidation(1, 50, props?.formData?.user_name) && submitButtonClicked}
          errorMessage={t('userNameValidation')}
        />
      </div>
      <Input
        type="text"
        label={t('mailID')}
        name="user_email"
        value={props?.formData?.email}
        change={handleChange}
        readonly
      />
      <Input
        type="password"
        label={t('password')}
        name="password"
        value={props?.formData?.password}
        change={handleChange}
        error={!passwordValidation(props?.formData?.password) && submitButtonClicked}
        errorMessage={t('passwordRequirements')}
      />
      <Input
        type="password"
        label={t('confirmPassword')}
        name="re_password"
        value={props?.formData?.re_password}
        change={handleChange}
        error={(!passwordValidation(props?.formData?.re_password)
          || props?.formData?.password !== props?.formData?.re_password)
          && submitButtonClicked}
        errorMessage={props?.formData?.password !== props?.formData?.re_password
          ? t('passwordMismatch')
          : t('passwordRequirements')}
      />
      {errorMsg && <p className="errorMsg">{errorMsg}</p>}
      <Button
        label={t('continue')}
        click={nextStage}
        classes="success-button"
        disabled={disable}
        loading={uploading}
      />
    </div>
  );
}
UserForm.propTypes = {
  formData: PropTypes.objectOf().isRequired,
  setFormData: PropTypes.func.isRequired,
  setFormStage: PropTypes.func.isRequired,
};

export default UserForm;
