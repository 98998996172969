import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import loginImage from '../../assets/images/login/loginPage.jpg';
import userIcon from '../../assets/icons/mail.png';
import { Input1 } from '../../shared/component/form/Input';
import Button from '../../shared/component/Button';

import auth from '../../utils/api/auth';
import { CloseIcon, SuccessTickIcon } from '../../shared/component/svgIcon';

function ForgotPassword() {
  const navigate = useNavigate();
  const [value, setValue] = useState({});
  const [disable, setDisable] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  // const [errorMessage, setError] = useState('');
  const [resetPassword, setResetPassword] = useState(false);

  useEffect(() => {
    localStorage.removeItem('data');
    if (value.email) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);

  const changeHandle = (name, changeValue) => {
    setValue({ ...value, [name]: changeValue });
  };

  const submit = async () => {
    setDisable(true);
    setButtonLoading(true);
    try {
      await auth.forgotPassword(value);
      setResetPassword(true);
      setDisable(false);
      setButtonLoading(false);
      setTimeout(() => {
        setResetPassword(false);
        navigate('/login');
      }, 10000);
    } catch (error) {
      setDisable(false);
      setButtonLoading(false);
      setResetPassword(true);
      setTimeout(() => {
        setResetPassword(false);
        navigate('/login');
      }, 10000);
    }
  };

  return (
    <div className="login">
      <div className="page">
        <div className="banner-wrap">
          <img src={loginImage} className="forgot-password-banner" alt="" />
        </div>
        <div className="form-session">
          <div className="form forgot-login-forms">
            <div className="title-container">
              <h1 className="loginTitles">Forgot Your Password?</h1>
              <p className="loginP">
                Enter the registered email address and we&apos;ll send you instructions to reset your password.
              </p>
              {resetPassword
                && (
                  <div className="reset-password-message">
                    <span><SuccessTickIcon /></span>
                    <p>If you are the existing user, a link to reset your password has been emailed to you.</p>
                    <span
                      role="presentation"
                      onClick={() => setResetPassword(false)}
                    >
                      <CloseIcon
                        width="20px"
                        height="20px"
                        color="#212B36"
                      />
                    </span>
                  </div>
                )}
            </div>
            {/* {(errorMessage && !resetPassword) && <p className="text-danger">{errorMessage}</p>} */}
            <Input1
              icon={userIcon}
              label="Email Address"
              type="text"
              name="email"
              change={changeHandle}
            />
            <Button
              label="Send Mail"
              click={submit}
              classes={buttonLoading ? 'success-button loading-btn' : 'success-button'}
              disabled={disable}
              loading={buttonLoading}
            />
            <div className="loginButton"><Link to="/login">Back to Login</Link></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
