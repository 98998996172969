import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import DataTable from '../Table';

import TopContent from '../../shared/component/TopContent';
import DeleteModal from '../../shared/component/DeleteModal';
import DropDownMenu from '../../shared/component/DropDownMenu';
import PopupModal from '../../shared/component/PopupModel';

import displayGroupsApi from '../../utils/api/devices-group';

import {
  fetchDisplayGroupList,
  setFilter,
  singleDisplayGroupAdd,
  setTableFilter,
  deleteDisplayGroup,
} from '../../redux/slices/DisplayGroupSlice';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';
import { checkFeatures, checkPermission } from '../../utils/helpers';
import exportCSV from '../Global/exportCSV';
import { fetchUpdateDeviceGroups } from '../../redux/slices/OtaUpdateSlice';
import OtaUpdateGroup from './components/otaUpdateGroup';
import { MoreMenuIcon } from '../../shared/component/svgIcon';
import height from '../../utils/size-variables';

function DisplayGroups() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    displayGroup,
    pageCount,
    totalPageCount,
    status,
    filter,
    tableFilter,
    totalDataCount,
  } = useSelector((state) => state.displayGroup);

  const {
    updateDeviceGroups,
    status: updateDeviceStatus,
  } = useSelector((state) => state.otaUpdate);

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [displayGroupId, setDisplayGroupId] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deviceUpdate, setDeviceUpdate] = useState(false);
  const [updatePopup, setUpdatePopup] = useState(false);

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});

  const getDisplayGroups = (counts, search) => {
    if (status === 'succeeded') {
      dispatch(fetchDisplayGroupList({ page: counts, limit: 20, ...search }));
    }
  };

  const handleScroll = () => {
    getDisplayGroups(pageCount + 1, filter);
  };

  const getDeleteDisplayGroupId = (id) => {
    setDisplayGroupId(id);
    setOpenDeleteModal(true);
  };

  const viewDisplayGroup = (id) => {
    navigate(`view/${id}`);
  };

  const deleteDisplayGroupById = () => {
    displayGroupsApi.deleteDevicesGroup(displayGroupId)
      .then(() => {
        dispatch(deleteDisplayGroup(displayGroupId));
        setOpenDeleteModal(false);
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const actions = [
    {
      name: t('view'),
      url: '',
      function: viewDisplayGroup,
      key: 'view',
      disabled: !checkPermission('getDisplayGroup') || !checkFeatures('display_groups'),
    },
    {
      name: t('delete'),
      url: '',
      function: getDeleteDisplayGroupId,
      key: 'delete',
      disabled: !checkPermission('deleteDisplayGroup') || !checkFeatures('display_groups'),
    },
  ];

  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('deviceGroups'),
      accessorKey: 'name',
    },
    {
      header: t('totalDevices'),
      accessorKey: 'count',
      filterFn: 'commonFIlterFn',
      enableSorting: false,
      muiFilterTextFieldProps: { type: 'number', onWheel: (e) => e.target.blur() },
      Cell: ({ row }) => (
        <div>
          {row.original.devices_list
            && (
              <PopupModal list={row.original.devices_list} name="devices" />
            )}
          {row.original.devices && !row.original.devices_list
            && (
              <span>{row.original.devices?.length}</span>
            )}
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'id',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 50,
      Cell: ({ row }) => (
        <DropDownMenu
          action={actions}
          icon={<MoreMenuIcon />}
          value={row?.original}
          dropdown="tableForm"
          tooltip=""
        />
      ),
    },
  ];

  const addDisplayGroups = () => {
    navigate('add');
  };

  const exportData = () => {
    const columnHeaders = ['name', 'devices'];

    // Filter the hidden column
    const filteredHeaders = columnHeaders.filter((header) => {
      if (header === 'devices') {
        return columnVisibility.count !== false;
      }
      return columnVisibility[header] !== false;
    });

    // API call for export CSV
    displayGroupsApi.deviceGroupExport({ fields: filteredHeaders, ...filter })
      .then((res) => {
        const results = [];
        res?.data?.forEach((item) => {
          if (item?.devices) {
            item?.devices?.forEach((device) => {
              results.push({
                name: item?.name,
                device_name: device?.name,
              });
            });
          } else {
            results.push({
              name: item?.name,
            });
          }
        });

        // Loop through each object in the array and remove undefined keys
        const updatedResults = results.map((obj) => {
          const newObj = {};
          Object.keys(obj).forEach((key) => {
            if (obj[key] !== undefined) {
              newObj[key] = obj[key];
            }
          });
          return newObj;
        });

        // Function to export as CSV file
        exportCSV(Object.keys(updatedResults?.[0]), updatedResults);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  // Toggle functionality between device_group and update_screen
  const checkForUpdates = () => {
    dispatch(fetchUpdateDeviceGroups());
    setDeviceUpdate(true);
  };

  const closeUpdatePopup = () => {
    setUpdatePopup(false);
  };

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilter(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getDisplayGroups(1, filteredValues);
  };

  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(tableFilter).length === 0) {
      getDisplayGroups(1);
    }
  }, []);

  // To open a popup for update button when selected list changes
  useEffect(() => {
    setUpdatePopup(true);
  }, [rowSelection]);

  // If a user is navigated back from display group add-view page,
  // then clear the redux data for singleDisplayGroup
  useEffect(() => {
    dispatch(singleDisplayGroupAdd({}));
  }, []);

  return (
    <div className="content">
      <OtaUpdateGroup
        rowSelection={Object.keys(rowSelection)}
        openPopup={Object.keys(rowSelection)?.length > 0 && updatePopup}
        setDeviceUpdate={setDeviceUpdate}
        setRowSelection={setRowSelection}
        closePopup={closeUpdatePopup}
      />
      <div className="main-content">
        <TopContent
          label={t('deviceGroups')}
          buttonClass="success-button display-groups-createbutton"
          button={checkPermission('addDisplayGroup') && checkFeatures('display_groups')}
          buttonLabel={t('newDisplayGroups')}
          click={addDisplayGroups}
        />
        <DeleteModal
          status={openDeleteModal}
          closeModal={setOpenDeleteModal}
          okFunction={deleteDisplayGroupById}
          buttonLabel={t('delete')}
          title={t('areYouSureYouWantToDeleteThisDisplayGroup?')}
          subTitle=<span> {t('thisWillDeleteThisDisplayGroupPermanently')} <br />{t('youCannotUndoThisAction.')}</span>
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area group-table">
              {!deviceUpdate
                ? (
                  <DataTable
                    header={columns}
                    value={displayGroup}
                    status={status}
                    onFilterOrSortingChange={onFilterOrSortingChange}
                    reduxColumnFiltersSorting={tableFilter}
                    totalPageCount={totalPageCount}
                    pageCount={pageCount}
                    scrollData={handleScroll}
                    totalDataCount={totalDataCount}
                    rowSelection={[]}
                    columnVisibility={columnVisibility}
                    setColumnVisibility={setColumnVisibility}
                    setRowSelection={setRowSelection}
                    enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
                    height={height.onlyTableHeight}
                    exportButton={
                      checkFeatures('display_groups')
                      && checkPermission('exportDisplayGroup')
                    }
                    exportData={exportData}
                    newButtonLabel="Check For Updates"
                    newButtonClickEvent={checkForUpdates}
                    key="displayGroup"
                    isFirstRender={isFirstRender}
                  />
                ) : (
                  <DataTable
                    header={columns}
                    value={updateDeviceGroups}
                    status={updateDeviceStatus}
                    onFilterOrSortingChange={onFilterOrSortingChange}
                    reduxColumnFiltersSorting={tableFilter}
                    totalPageCount={totalPageCount}
                    pageCount={pageCount}
                    scrollData={handleScroll}
                    totalDataCount={totalDataCount}
                    enableRowSelection={deviceUpdate}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    columnVisibility={columnVisibility}
                    setColumnVisibility={setColumnVisibility}
                    enable={false}
                    height={height.onlyTableHeight}
                    newButtonLabel="Device Group"
                    newButtonClickEvent={() => setDeviceUpdate(false)}
                    key="updateDeviceGroup"
                    isFirstRender={isFirstRender}
                    disableFilter
                    disableSorting
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
DisplayGroups.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      devices_list: PropTypes.arrayOf(),
      devices: PropTypes.arrayOf(),
    }),
  }),
};
DisplayGroups.defaultProps = {
  row: {},
};
export default DisplayGroups;
