import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared/component/Button';
import { InputTime } from '../../../shared/component/form/Input';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function ModalContent({
  onSave,
  selectedValue,
  closeModal,
  columnFilter,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [timeArray, setTimeArray] = useState([
    { start_time: '', end_time: '' },
  ]);

  const selectDataTime = (index, name, time) => {
    if (name === 'start_time' || name === 'end_time') {
      const hour = time?.$H;
      const minute = time?.$m;

      // Ensure 24-hour format with leading zeros
      const formattedTime = `${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`;

      setTimeArray((prevArray) => prevArray.map((dateTime, i) => (i === index
        ? { ...dateTime, [name]: formattedTime } : dateTime)));
    }
  };

  const addNewTimeInput = () => {
    setTimeArray((prevArray) => [
      ...prevArray,
      { start_time: '', end_time: '' },
    ]);
  };

  const isEndTimeValid = timeArray.every(({ start_time: startTime, end_time: endTime }) => {
    const [startHour, startMinute] = startTime.split(':').map(Number);
    const [endHour, endMinute] = endTime.split(':').map(Number);

    const startInMinutes = startHour * 60 + startMinute;
    const endInMinutes = endHour * 60 + endMinute;

    // Ensure that end time is strictly greater than start time
    return endInMinutes > startInMinutes;
  });

  const handleSave = () => {
    // Check if any start_time or end_time is unselected (empty string)
    const unSelectedTime = timeArray.some((time) => time?.start_time === '' || time?.end_time === '');

    // If any time is unselected or if any end_time is not greater than start_time, show error
    if (unSelectedTime || !isEndTimeValid) {
      dispatch(setErrorNotification({ message: 'Selected time is invalid' }));
      return;
    }

    // Otherwise, proceed with save
    onSave(columnFilter, timeArray);
    closeModal();
  };

  useEffect(() => {
    if (selectedValue?.volume_play_range?.length) {
      setTimeArray(selectedValue?.volume_play_range);
    }
  }, []);

  return (
    <div className="schedule-form">
      <div className="form-field-row">
        <div className="form-field-1">
          {timeArray.map((dateTime, index) => (
            <div
              // key={index}
              className="time-input-group"
              style={{ display: 'flex', gap: '20px', marginTop: '10px' }}
            >
              <InputTime
                label="From"
                name="start_time"
                value={dateTime?.start_time ? dayjs(`2014-09-27 ${dateTime.start_time}`) : ''}
                change={(name, time) => selectDataTime(index, name, time)}
              />
              <InputTime
                label="To"
                name="end_time"
                value={dateTime?.end_time ? dayjs(`2014-09-27 ${dateTime.end_time}`) : ''}
                change={(name, time) => selectDataTime(index, name, time)}
              />
            </div>
          ))}
        </div>
        <div className="add-new-button" style={{ marginTop: '20px' }}>
          <Button
            label={t('addNew')}
            classes="add-button borderless-button"
            click={addNewTimeInput}
          />
        </div>
        <div className="form-button-group">
          <div className="form-button">
            <Button label="Back" click={closeModal} classes="default-button" />
          </div>
          <div className="form-button">
            <Button label="Save" click={handleSave} classes="success-button" />
          </div>
        </div>
      </div>
    </div>
  );
}

ModalContent.propTypes = {
  onSave: PropTypes.func.isRequired,
  columnFilter: PropTypes.objectOf().isRequired,
  selectedValue: PropTypes.objectOf().isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ModalContent;
