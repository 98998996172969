import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import logo from '../../assets/images/WejhaLogo.png';
import PassWordAutoGenerated from './component/PasswordAutoGenerated';
import LicenseValidation from './component/licenseValidation';
import ServerConfig from './component/serverConfig';
import Button from '../../shared/component/Button';
import config from '../../utils/api/config';

function LicenseActive() {
  const navigate = useNavigate();

  const submit = () => {
    navigate('/login');
  };
  const [formStage, setFormStage] = useState(1);
  useEffect(() => {
    setTimeout(() => {
      config.getLicenseDetails()
        .then(() => {
          navigate('/login');
        })
        .catch(() => {
          setFormStage(2);
        });
    }, 1000);
  }, []);
  return (
    <div className="license-container">
      <div className="logo-container">
        <img src={logo} alt="logo" className="logo-image" />
      </div>
      {formStage === 1 && (
        <div className="loader-container">
          <div className="loader-bar" />
        </div>
      )}
      {formStage === 5 && (
        <PassWordAutoGenerated
          formStage={setFormStage}
        />
      )}
      {formStage === 2 && (
        <LicenseValidation
          formStage={setFormStage}
        />
      )}
      {formStage === 3 && (
        <ServerConfig
          formStage={setFormStage}
        />
      )}
      {formStage === 4 && (
        <div className="license-activation-done">
          <div className="tick-image">
            <div className="confetti" />
            <div className="confetti" />
            <div className="confetti" />
            <div className="confetti" />
          </div>
          <div className="footer-content">
            <h3>All Done!!</h3>
            <div className="sub-content">
              <span>Lorem ipsum dolor sit amet, consectetur adipiscing </span>
              <p>incididunt ut labore et dolore magna aliqua</p>
            </div>
            <div>
              <Button
                label="Continue"
                click={submit}
              />
            </div>
          </div>
        </div>
      )}

    </div>
  );
}

export default LicenseActive;
