/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import cookies from 'js-cookie';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  contentUpload, defaultTemplateData, getCurrentTemplate, setPage,
} from '../../../redux/slices/TemplateSlice';
import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';
import { checkFeatures, checkPermission } from '../../../utils/helpers';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import CustomBannerTemplate from './CustomBannerTemplate';

function TemplateCategory({ data, listed, color }) {
  const currentLanguageCode = cookies?.get('i18next') || 'en';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  // Reference to the slider component
  useEffect(() => {
  }, [data, listed, color]); // Run only once when component mounts
  const permissionError = {
    message: ' View permission denied',
  };
  const handleTemplateClick = (template) => {
    if (!checkPermission('getTemplatesList')
      || (!checkFeatures('templates') && (checkFeatures('templates')
        || !checkFeatures('templates')))) {
      dispatch(setErrorNotification(permissionError));
    }
    if (checkPermission('getTemplatesList')
      && checkFeatures('templates')
    ) {
      dispatch(getCurrentTemplate(template));
      dispatch(defaultTemplateData(template));
      dispatch(setPage('tab-1'));
      dispatch(contentUpload('create'));
      navigate(`/admin/templates/edit-content/${template?.template_number}`);
    }
    // if (checkPermission('getTemplatesList')
    //   && (checkFeatures('templates') && !checkFeatures('templates'))) {
    //   dispatch(getCurrentTemplate(template));
    //   dispatch(setPage('tab-1'));
    //   navigate(`/admin/templates/view-image/${template?.template_number}`);
    // }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    rows: 1,
    nextArrow: (currentLanguageCode === 'en') && <RightArrow />,
    prevArrow: (currentLanguageCode === 'ar') && <LeftArrow />,
  };
  const settingsSlide = {
    dots: false,
    infinite: false,
    // speed: 500,
    // slidesToShow: 5,
    // slidesToScroll: 5,
    initialSlide: 0,
    rows: 1,
  };

  // Function to get the number of slides displayed

  return (
    <>
      {(color)
        && (
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '40%' }}>
            <CircularProgress color="success" />
          </Box>
        )}
      {(!color) && (listed === 'All') && (
        <div style={currentLanguageCode === 'ar' ? { width: '98%' } : { width: '99%', marginLeft: '15px' }}>
          {data?.map((value, index) => (
            <>
              {index === 1 && <CustomBannerTemplate />}
              <p style={currentLanguageCode === 'ar' ? { paddingRight: '40px' } : { paddingLeft: '5px' }}>
                {value?._id}
              </p>
              {value?.documents?.length >= 5 ? (
                <Slider {...settings} ref={sliderRef}>
                  {value?.documents?.map((item) => (
                    <div
                      style={currentLanguageCode === 'ar' ? { margin: '0px', padding: '0px' }
                        : { marginLeft: 'inherit' }}
                      role="none"
                      key={item?.id}
                    >
                      <img
                        style={{ padding: '5px', boxShadow: '0px 1.08px 8.67px 1.08px rgba(0, 0, 0, 0.0784313725)' }}
                        src={item?.template_image_url}
                        alt="templates"
                        onClick={() => handleTemplateClick(item)}
                        role="none"
                      />
                      <p style={{ textAlign: 'center', fontSize: '13px' }}>{item?.template_name}</p>
                    </div>
                  ))}
                </Slider>
              ) : (
                <Slider
                  className="slick-list-custom"
                  {...settingsSlide}
                  slidesToShow={value?.documents?.length}
                  ref={sliderRef}
                >
                  {value?.documents?.map((item) => (
                    <div
                      style={currentLanguageCode === 'ar' ? { margin: '0px', padding: '0px' }
                        : { marginLeft: '200px', width: 'max-content' }}
                      onClick={() => handleTemplateClick(item)}
                      role="presentation"
                      key={item?.id}
                    >
                      <img
                        style={{ padding: '5px', boxShadow: '0px 1.08px 8.67px 1.08px rgba(0, 0, 0, 0.0784313725)' }}
                        src={item?.template_image_url}
                        alt="templates"
                        onClick={() => handleTemplateClick(item)}
                        role="none"
                      />
                      <p style={{ textAlign: 'center', fontSize: '13px' }}>{item?.template_name}</p>
                    </div>
                  ))}
                </Slider>
              )}
            </>
          ))}
        </div>
      )}

      {listed !== 'All' && (
        <div style={currentLanguageCode === 'ar' ? { paddingRight: '40px' } : { paddingLeft: '5px' }}>
          {data?.map((value) => (
            (value?._id === listed) && (
              <>
                <p>{value?._id}</p>
                {
                  value?.documents?.length >= 5 ? (
                    <Slider {...settings} ref={sliderRef}>
                      {value?.documents?.map((item) => (
                        <div
                          style={{ width: 'max-content' }}
                          onClick={() => handleTemplateClick(item)}
                          role="none"
                          key={item?.id}
                        >
                          <img src={item?.template_image_url} alt="templates" />
                          <p style={{ textAlign: 'center', fontSize: '13px' }}>{item?.template_name}</p>
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    <Slider {...settingsSlide} slidesToShow={value?.documents?.length} ref={sliderRef}>
                      {value?.documents?.map((item) => (
                        <div
                          style={{ width: 'max-content' }}
                          onClick={() => handleTemplateClick(item)}
                          role="none"
                          key={item?.id}
                        >
                          <img src={item?.template_image_url} alt="templates" />
                          <p style={{ textAlign: 'center', fontSize: '13px' }}>{item?.template_name}</p>
                        </div>
                      ))}
                    </Slider>
                  )
                }
              </>
            )
          ))}
        </div>
      )}
    </>
  );
}

TemplateCategory.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      templateImageUrl: PropTypes.string.isRequired,
      documents: PropTypes.arrayOf(),
    }),
  ).isRequired, // Ensure templateData is an array of objects with specific shape
  listed: PropTypes.string.isRequired,
  color: PropTypes.bool.isRequired,
};

export default TemplateCategory;
