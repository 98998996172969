import { get, post } from './base/index';

export default {
  dataBaseConnection: (data = {}) => post(`${process.env.REACT_APP_API_URL}cms/v1/config/create-db`, data),
  companyCreation: (data = {}) => post(`${process.env.REACT_APP_API_URL}cms/v1/config/company-creation`, data),
  StorageConnection: (data = {}) => post(`${process.env.REACT_APP_API_URL}cms/v1/config/create-storage`, data),
  SMTPConnection: (data = {}) => post(`${process.env.REACT_APP_API_URL}cms/v1/config/smtp-connection`, data),
  OpenSearchConnection: (data = {}) => post(`${process.env.REACT_APP_API_URL}cms/v1/config/open-search`, data),
  getLicenseDetails: (data = {}) => get(`${process.env.REACT_APP_API_URL}cms/v1/config/getLicense`, data),
};
