import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import TopContent from '../../../shared/component/TopContent';
import loginImage from '../../../assets/images/loginPage.jpg';
import youtubeIcon from '../../../assets/images/youtube-icon.png';
import urlIcon from '../../../assets/images/url-image.png';
import pdfIcon from '../../../assets/images/pdf-icon.png';

import { fetchSinglePlaylist } from '../../../redux/slices/PlaylistSlice';
import DataTable from '../../Table';
import ContentView from '../../MyContent/components/ContentView';
import ModalBox from '../../../shared/component/ModalBox';
import { changeDateTimeFormat, checkFeatures, checkPermission } from '../../../utils/helpers';
import height from '../../../utils/size-variables';

function PlayListView() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { singlePlaylist } = useSelector((state) => state.playlist);
  const { status } = useSelector((state) => state.playlist);

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [contentSelection, setContentSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});

  const [modalView, setModalView] = useState();
  const [contentModalView, setContentModalView] = useState();

  const viewContent = (singleContent) => {
    setModalView({
      title: 'Preview',
      content: <ContentView singleContent={singleContent} />,
    });
    setContentModalView(true);
  };

  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('contentName'),
      accessorKey: 'name',
      Cell: ({ row }) => (
        <div className="content-thumbnail">
          {
            (() => {
              let thumbnailImage;
              if (row.original.file_type === 'youtube') {
                thumbnailImage = (
                  <img
                    alt="Youtube"
                    src={youtubeIcon}
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                    className="default-image"
                  />
                );
              } else if (row.original.file_type === 'url') {
                thumbnailImage = (
                  <img
                    alt="Url"
                    src={urlIcon}
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                    className="default-image"
                  />
                );
              } else if (row.original.file_type.includes('pdf')) {
                thumbnailImage = (
                  <img
                    alt="Url"
                    src={pdfIcon}
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                    className="default-image"
                  />
                );
              } else {
                thumbnailImage = (
                  <img
                    alt="thumbnail"
                    src={
                      row.original.thumbnailSignedUrls?.px_50x50
                      || loginImage
                    }
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                  />
                );
              }
              return thumbnailImage;
            })()
          }
          <span className="content_name">
            {row.original.name}
          </span>
        </div>
      ),
    },
    {
      header: t('mediaType'),
      accessorKey: 'file_type',
      enableSorting: false,
      Cell: ({ row }) => row.original?.file_type_to_show,
    },
    {
      header: t('managedBy'),
      accessorKey: 'author',
    },
    {
      header: t('updatedBy'),
      accessorKey: 'updatedAt',
      accessorFn: (originalRow) => new Date(originalRow.updatedAt),
      filterVariant: 'date-range',
      enableColumnFilter: false,
      Cell: ({ row }) => changeDateTimeFormat(row.original.updatedAt) || '',
    },
    {
      header: t('fileSize'),
      accessorKey: 'file_size',
      enableColumnFilter: false,
    },
  ];

  const addPlaylist = () => {
    navigate(`../edit/${params?.id}`);
  };

  useEffect(() => {
    dispatch(fetchSinglePlaylist(params?.id));
  }, []);

  return (
    <div className="content content-wrap">
      <ModalBox
        status={contentModalView}
        closeModal={setContentModalView}
        modalView={modalView}
        notification={false}
      />
      <div className="main-content">
        <TopContent
          label={[t('playlist'), singlePlaylist?.name]}
          button={checkFeatures('playlist') && checkPermission('editPlaylist')}
          buttonLabel={t('editPlaylist')}
          buttonClass="default-button button-outline-success-1"
          click={addPlaylist}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area">
              <DataTable
                header={columns}
                value={singlePlaylist?.contents || []}
                onFilterOrSortingChange={() => { }}
                isFirstRender
                status={status}
                totalPageCount={1}
                pageCount={1}
                scrollData={() => { }}
                totalDataCount={1}
                setColumnFilters={setColumnFilters}
                setGlobalFilter={setGlobalFilter}
                setSorting={setSorting}
                columnFilters={columnFilters}
                globalFilter={globalFilter}
                rowSelection={contentSelection}
                setRowSelection={setContentSelection}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                sorting={sorting}
                enable={false}
                height={height.onlyTableHeight}
                disableFilter
                disableSorting
                disableFullScreenToggle
                disableFilterButton
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
PlayListView.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      file_type: PropTypes.string.isRequired,
      thumbnailSignedUrls: PropTypes.shape({
        px_50x50: PropTypes.string.isRequired,
      }),
      name: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
    }).isRequired,
  }),
};
PlayListView.defaultProps = {
  row: null,
};

export default PlayListView;
