/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { InputRightInnerIcon } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import { confirmPasswordValidation, passwordValidation } from '../../../shared/component/form/Validation';
import EyeIcon from '../../../assets/images/eye-Icon.png';
import EyeHideIcon from '../../../assets/images/eye-hide-icon.png';
import ModalBox from '../../../shared/component/ModalBox';
import Success from '../../../shared/component/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import auth from '../../../utils/api/auth';
import { getLocalStorageItem } from '../../../utils/helpers';
import { AddItemIcon, ConfigPlusIcon, PlusIcon } from '../../../shared/component/svgIcon';

function Configurations() {
  const getCompanyDetails = getLocalStorageItem('data');
  const smtpConfig = getCompanyDetails?.user?.company?.smtp_config;
  const openSearchConfig = getCompanyDetails?.user?.company?.open_search_config;
  console.log(smtpConfig, openSearchConfig);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [type, setType] = useState('password');
  const [image, setImage] = useState(EyeIcon);
  const [newPasswordType, setNewPasswordType] = useState('password');
  const [setNewPasswordImage] = useState(EyeIcon);
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const [setConfirmPasswordImage] = useState(EyeIcon);
  const [value, setValue] = useState({});
  const [disable, setDisable] = useState(true);
  const [setSubmitButtonClicked] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [notification, setNotification] = useState(false);
  const [localStorageClassName, setLocalStorageClassName] = useState('storage-header-selected');
  const [cloudStorageClassName, setCloudStorageClassName] = useState('storage-header');
  const [storageTabToShow, setStorageTabToShow] = useState('local');

  const handleChange = (name, changeValue) => {
    setValue({ ...value, [name]: changeValue });
  };
  const click = (funValue) => {
    if (funValue === 'newPassword') {
      setNewPasswordType(newPasswordType === 'password' ? 'text' : 'password');
      setNewPasswordImage(newPasswordType === 'password' ? EyeHideIcon : EyeIcon);
    } else if (funValue === 'confirmPassword') {
      setConfirmPasswordType(confirmPasswordType === 'password' ? 'text' : 'password');
      setConfirmPasswordImage(confirmPasswordType === 'password' ? EyeHideIcon : EyeIcon);
    } else {
      setType(type === 'password' ? 'text' : 'password');
      setImage(type === 'password' ? EyeHideIcon : EyeIcon);
    }
  };
  const reset = () => {
    setValue({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
  };
  const submit = () => {
    setSubmitButtonClicked(true);
    if (passwordValidation(value?.newPassword)
      && !confirmPasswordValidation(value?.newPassword, value?.confirmPassword)) {
      setUploading(true);
      auth.changePassword(value)
        .then(() => {
          setUploading(false);
          setNotification(true);
          setTimeout(() => {
            setNotification(false);
          }, 3000);
        })
        .catch((err) => {
          setUploading(false);
          setDisable(false);
          dispatch(setErrorNotification(err?.response?.data));
        });
    }
  };

  const handleStorageClick = (type) => {
    if (type === 'cloud') {
      setCloudStorageClassName('storage-header-selected');
      setLocalStorageClassName('storage-header');
      setStorageTabToShow(type);
    } else {
      setLocalStorageClassName('storage-header-selected');
      setCloudStorageClassName('storage-header');
      setStorageTabToShow(type);
    }
  }
  console.log(storageTabToShow, 8777)
  useEffect(() => {
    if (value?.oldPassword && value?.newPassword && value?.confirmPassword) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  });
  const [modal, setModal] = useState(null);
  const handleAddClick = (PlusIcon) => {
    if (PlusIcon === 'smtp') {
      setModal(PlusIcon);
    }
    if (PlusIcon === 'openSearch') {
      setModal(PlusIcon);
    }
  };

  const close = () => {
    setModal(null);
  }

  return (
    <div className="configurations">
      <ModalBox
        status={notification}
        closeModal={setNotification}
        notification
        modalView={{ content: <modal message={t('passwordUpdatedSuccess')} /> }}
      />
      <div className="form-fields">
        <p className="header">{t('databaseConfiguration')}</p>
        <hr className="line" />
        <div className="form-fields-single-row">
          <InputRightInnerIcon
            icon={image}
            type={type}
            label={t('mongoDbUrl')}
            name="mongoDbUrl"
            value={value?.oldPassword}
            change={handleChange}
            click={click}
            placeholder={t('mongoDbUrl')}
          />
        </div>
      </div>
      <div className="form-fields">
        <div className='storage-tab'>
          <p className={localStorageClassName} onClick={() => handleStorageClick('local')}>{t('localStorageConfiguration')}</p>
          <p className={cloudStorageClassName} onClick={() => handleStorageClick('cloud')}>{t('cloudStorageConfiguration')}</p>
        </div>
        <hr className="line" />
        {storageTabToShow === 'local' && <div className="form-fields-single-row">
          <InputRightInnerIcon
            type={type}
            label={t('storagePath')}
            name="storagePath"
            value={value?.oldPassword}
            change={handleChange}
            click={click}
            placeholder={t('storagePath')}
          />
        </div>
        }
        {storageTabToShow === 'cloud' && <div className="form-fields-single-row">
          <div className="input-field">
            <InputRightInnerIcon
              type={type}
              label={t('awsBucketName')}
              name="awsBucketName"
              value={value?.oldPassword}
              change={handleChange}
              click={click}
              placeholder={t('enterAwsBucketName')}
            />
            <InputRightInnerIcon
              type={type}
              label={t('awsRegion')}
              name="awsRegion"
              value={value?.oldPassword}
              change={handleChange}
              click={click}
              placeholder={t('enterAwsRegion')}
            />
          </div>
          <div className="input-field">
            <InputRightInnerIcon
              type={type}
              label={t('awsAccessKey')}
              name="awsAccessKey"
              value={value?.oldPassword}
              change={handleChange}
              click={click}
              placeholder={t('enterAwsAccessKey')}
            />
            <InputRightInnerIcon
              type={type}
              label={t('awsSecretKey')}
              name="awsSecretKey"
              value={value?.oldPassword}
              change={handleChange}
              click={click}
              placeholder={t('enterAwsSecretKey')}
            />
          </div>
        </div>
        }
        <div className="form-button-group-config">
          {/* <div className="form-button">
            <Button
              label={t('saveChanges')}
              click={reset}
              classes="default-button"
            />
          </div> */}
          <div className="form-button">
            <Button
              label={uploading ? t('saveChanges...') : t('saveChanges')}
              click={submit}
              classes={uploading ? 'success-button loading-btn' : 'success-button'}
              loading={uploading}
              disabled={disable}
            />
          </div>
        </div>
      </div>
      {(!smtpConfig && (!modal || modal === 'openSearch')) && <div className="new-storage-header-wrapper" onClick={() => handleAddClick('smtp')}>
        <ConfigPlusIcon />
        <p className="new-storage-header">{t('smtpConfiguration')}</p>
      </div>}
      {(smtpConfig || modal === 'smtp') && <div className="form-fields">
        <p className="storage-header">{t('smtpConfiguration')}</p>
        <hr className="line" />
        <div className="form-fields-single-row">
          <div className="input-field">
            <InputRightInnerIcon
              type={type}
              label={t('hostName')}
              name="hostName"
              value={value?.oldPassword}
              change={handleChange}
              click={click}
              placeholder={t('enterHostName')}
            />
            <InputRightInnerIcon
              type={type}
              label={t('port')}
              name="port"
              value={value?.oldPassword}
              change={handleChange}
              click={click}
              placeholder={t('enterPort')}
            />
          </div>
          <div className="input-field">
            <InputRightInnerIcon
              type={type}
              label={t('userName')}
              name="userName"
              value={value?.oldPassword}
              change={handleChange}
              click={click}
              placeholder={t('enterUserName')}
            />
            <InputRightInnerIcon
              icon={image}
              type={type}
              label={t('password')}
              name="password"
              value={value?.oldPassword}
              change={handleChange}
              click={click}
              placeholder={t('enterPassword')}
            />
          </div>
          <div className="input-field-single">
            <InputRightInnerIcon
              type={type}
              label={t('fromEmail')}
              name="fromEmail"
              value={value?.oldPassword}
              change={handleChange}
              click={click}
              placeholder={t('enterFromEmail')}
            />
          </div>
        </div>
        <div className="form-button-group-config">
         { !smtpConfig && <div className="form-button">
            <Button
              label={t('close')}
              click={close}
              classes="default-button"
            />
          </div> }
          <div className="form-button">
            <Button
              label={uploading ? t('saveChanges...') : t('saveChanges')}
              click={submit}
              classes={uploading ? 'success-button loading-btn' : 'success-button'}
              loading={uploading}
              disabled={disable}
            />
          </div>
        </div>
      </div>}
      {(!openSearchConfig && (!modal || modal === 'smtp') ) && <div className="new-storage-header-wrapper" onClick={() => handleAddClick('openSearch')}>
        <ConfigPlusIcon />
        <p className="new-storage-header">{t('openSearchConfiguration')}</p>
      </div>}
      {(openSearchConfig || modal === 'openSearch') && <div className="form-fields">
        {/* <div className='storage-tab'> */}
        <p className="storage-header">{t('openSearchConfiguration')}</p>
        {/* <p className={cloudStorageClassName} onClick={() => handleStorageClick('cloud')}>{t('cloudStorageConfiguration')}</p> */}
        {/* </div> */}
        <hr className="line" />
        <div className="form-fields-single-row">
          <div className="open-search-field">
            <InputRightInnerIcon
              type={type}
              label={t('host')}
              name="host"
              value={value?.oldPassword}
              change={handleChange}
              click={click}
              placeholder={t('enterHost')}
            />
          </div>
          <div className="input-field">
            <InputRightInnerIcon
              label={t('userName')}
              name="userName"
              value={value?.oldPassword}
              change={handleChange}
              click={click}
              placeholder={t('enterUserName')}
            />
            <InputRightInnerIcon
              icon={image}
              type={type}
              label={t('password')}
              name="password"
              value={value?.oldPassword}
              change={handleChange}
              click={click}
              placeholder={t('enterPassword')}
            />
          </div>
          {/* <div className="input-field">
            <InputRightInnerIcon
              type={type}
              label={t('userName')}
              name="userName"
              value={value?.oldPassword}
              change={handleChange}
              click={click}
              placeholder={t('enterUserName')}
            />
            <InputRightInnerIcon
              type={type}
              label={t('password')}
              name="password"
              value={value?.oldPassword}
              change={handleChange}
              click={click}
              placeholder={t('enterPassword')}
            />
          </div> */}

        </div>
        <div className="form-button-group-config">
         {!openSearchConfig && <div className="form-button">
            <Button
              label={t('close')}
              click={close}
              classes="default-button"
            /> 
          </div> }
          <div className="form-button">
            <Button
              label={uploading ? t('saveChanges...') : t('saveChanges')}
              click={submit}
              classes={uploading ? 'success-button loading-btn' : 'success-button'}
              loading={uploading}
              disabled={disable}
            />
          </div>
        </div>
      </div>}
    </div>
  );
}

export default Configurations;
