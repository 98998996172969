import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RadioButton } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import { checkFeatures, checkPermission } from '../../../utils/helpers';

function ContentOptions(props) {
  const { t } = useTranslation();
  const availableContentOptions = [
    {
      label: t('selectContentLayout'),
      key: 'layout',
      disabled: !checkFeatures('schedule')
        || !checkPermission('addContentLayoutSchedule'),
    },
    {
      label: t('selectContent'),
      key: 'content',
    },
  ];
  const [selectedContentType, setSelectedContentType] = useState('');

  const handleOptionSwitch = (value) => {
    if (value) {
      setSelectedContentType(value);
    }
  };

  const handleClickSubmit = () => {
    if (selectedContentType === 'layout') {
      props?.openSavedLayouts();
    } else {
      props?.openModalContent();
    }
  };

  return (
    <div className="schedule-add-content-options-wrap">
      <div className="content-options-wrap">
        {availableContentOptions?.map((option) => (
          <div className="each-content-option">
            <RadioButton
              label={option?.label}
              value={option?.key}
              checked={selectedContentType === option?.key}
              onChange={handleOptionSwitch}
              disabled={option?.disabled}
            />
          </div>
        ))}
      </div>
      <div className="rearrange-button-wrap">
        <Button
          label={t('back')}
          click={() => props?.closeModal()}
          classes="default-button"
        />
        <Button
          label={t('continue')}
          click={handleClickSubmit}
          classes="success-button"
          disabled={selectedContentType === ''}
        />
      </div>
    </div>
  );
}

ContentOptions.propTypes = {
  closeModal: PropTypes.func.isRequired,
  openModalContent: PropTypes.func.isRequired,
  openSavedLayouts: PropTypes.func.isRequired,
};

export default ContentOptions;
