/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector, useDispatch } from 'react-redux';
import {
  CircularProgress,
  Box,
} from '@mui/material';
import height from '../../../utils/size-variables';
import { fetchWidgets, setSelectedWidget, createWidgetStoreLocally } from '../../../redux/slices/WidgetTempSlice';
import { checkFeatures, checkPermission } from '../../../utils/helpers';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function WidgetCards() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const widgets = useSelector((state) => state.widgets.widgets);
  const loading = useSelector((state) => state.widgets.status);
  const handleCardClick = (widget) => {
    if (checkPermission('addApps') && checkFeatures('apps')) {
      dispatch(setSelectedWidget(widget));
      dispatch(createWidgetStoreLocally({}));
      switch (widget.name) {
        case 'Rss feed':
          navigate(`../rssfeed-template/add-RssFeed/${widget.id}`);
          break;
        case 'Clock':
          navigate('../clock-template');
          break;
        case 'Weather':
          navigate('../weather-template');
          break;
        default:
          break;
      }
    } else {
      dispatch(setErrorNotification({ message: 'Permission Denied!' }));
    }
  };

  useEffect(() => {
    if (widgets.length < 1) {
      dispatch(fetchWidgets());
    }
  }, [dispatch, widgets]);
  return (

    <div className="content-layout-container" style={{ flexWrap: 'wrap', gap: '50px' }}>
      <InfiniteScroll
        dataLength={widgets.length}
        height={height.cardsHeight}
        loader={loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress color="success" />
          </Box>
        )}
      >
        <div className="widget-card">
          {
            widgets.map((widget) => (
              <div
                key={widget?.id}
                className="widget-card-item"
              >
                <div
                  role="presentation"
                  className="widget-card-container"
                  onClick={() => handleCardClick(widget)}
                >
                  <img
                    src={widget?.image}
                    alt={widget.name}
                    className="widget-image"
                  />
                  <img
                    src={widget?.hover_image}
                    alt={widget.name}
                    className="hover-image"
                  />
                </div>
                <div>
                  {widget.name}
                </div>
              </div>
            ))
          }
        </div>
      </InfiniteScroll>
    </div>
  );
}

export default WidgetCards;
