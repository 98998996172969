/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Table from '../../Table';

import TopContent from '../../../shared/component/TopContent';
import { Input, Switch } from '../../../shared/component/form/Input';

import { fetchSingleDisplayGroup, updateDisplayGroup, updateSingleDisplayGroup }
  from '../../../redux/slices/DisplayGroupSlice';
import { checkFeatures, checkPermission } from '../../../utils/helpers';
import height from '../../../utils/size-variables';
import devicesGroup from '../../../utils/api/devices-group';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
// import { MoreMenuIcon } from '../../../shared/component/svgIcon';
// import DropDownMenu from '../../../shared/component/DropDownMenu';
// import GridViewButton from '../../../shared/component/GridViewButton';

function DisplayGroupsView() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { singleDisplayGroup } = useSelector((state) => state.displayGroup);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [allowSwitch, setAllowSwitch] = useState(true);
  const [rowIndex, setRowIndex] = useState(null);
  const addDisplayGroups = () => {
    navigate(`../edit/${singleDisplayGroup?.id}`);
  };
  const handleSwitch = async (data, index) => {
    setRowIndex(index);
    const deviceGroupData = {
      id: singleDisplayGroup._id,
      master: data._id,
    };
    if (allowSwitch) {
      setAllowSwitch(false);
      await devicesGroup.updateDevicesGroup(deviceGroupData)
        .then((res) => {
          dispatch(updateSingleDisplayGroup(res?.data));
          dispatch(updateDisplayGroup(res?.data));
          setAllowSwitch(true);
        }).catch((error) => {
          setAllowSwitch(true);
          dispatch(setErrorNotification(error?.response?.data));
        });
    }
  };
  // Column header for display signage table
  const columns = [
    {
      header: 'S.No',
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('deviceName'),
      accessorKey: 'name',
    },
    {
      header: t('deviceOS'),
      accessorKey: 'hardware',
    },
    {
      header: t('orientation'),
      accessorKey: 'orientation',
    },
    {
      header: t('synchronize'),
      accessorKey: 'synchronize',
      Cell: ({ row }) => (
        <Switch
          type="checkbox"
          name="enable-device-sync"
          change={() => handleSwitch(row.original, row.index)}
          loader={allowSwitch || row.index !== rowIndex}
          checked={row.original._id === singleDisplayGroup.master}
          id={`toggle-checkbox-${row?.index}`}
        />
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchSingleDisplayGroup(params?.id));
  }, [navigate]);

  useEffect(() => {
  }, [singleDisplayGroup]);

  return (
    <div className="content content-wrap">
      <div className="main-content">
        <TopContent
          label={[t('deviceGroups'), singleDisplayGroup?.name]}
          buttonClass="default-button button-outline-success-1"
          button={checkPermission('editDisplayGroup') && checkFeatures('display_groups')}
          buttonLabel={t('editDisplayGroup')}
          click={addDisplayGroups}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form">
              <div className="my-content-form">
                <Input
                  icon={' '}
                  label={t('displayGroupsName')}
                  type="text"
                  name="name"
                  required
                  readonly
                  value={singleDisplayGroup?.name}
                  change={() => { }}
                />
                <Input
                  icon={' '}
                  label={t('description')}
                  type="text"
                  name="description"
                  readonly
                  required={false}
                  value={singleDisplayGroup?.description}
                  change={() => { }}
                />
              </div>
              <Table
                header={columns}
                value={singleDisplayGroup.devices || []}
                status=""
                totalPageCount={1}
                pageCount={1}
                scrollData={() => { }}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                onFilterOrSortingChange={() => { }}
                rowSelection={{}}
                height={height.formTableHeight}
                disableFilterButton
                disableFullScreenToggle
                disableSorting
                disableFilter
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DisplayGroupsView.propTypes = {
  singleDisplayGroup: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    master: PropTypes.string,
    devices: PropTypes.arrayOf(PropTypes.shape({
      _id: PropTypes.string.isRequired,
    })),
  }).isRequired,
};
DisplayGroupsView.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      _id: PropTypes.string,
    }),
    index: PropTypes.number,
  }),
};
DisplayGroupsView.defaultProps = {
  row: {},
};
export default DisplayGroupsView;
