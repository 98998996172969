import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Count from './components/Count';
import Schedules from './components/Schedules';
import dashboard from '../../utils/api/dashboard';
import Location from './components/Location';
import DisplayOs from './components/DisplayOs';
import DisplayStatus from './components/DisplayStatus';
import StorageStats from './components/StorageStats';
// import ScheduleStatus from './components/ScheduledStatus';
import RecentActivity from './components/RecentActivity';
import { resetAllSlices } from '../../redux/slices/ReduxStateResetSlice';
// import SubscriptionRenewal from '../../shared/component/SubscriptionRenewal';
// import ModalBox from '../../shared/component/ModalBox';
import {
  // checkDaysBeforeExpiry,
  getDaysBetweenDates,
  getLocalStorageItem,
  // setLocalStorageItem,
} from '../../utils/helpers';

function Dashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const [count, setCount] = useState({});
  // const [setNotification] = useState(false);
  const [setSubscriptionStatus] = useState('');
  const getTotal = async () => {
    try {
      const res = await dashboard.getCount();
      setCount(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  // const closeModal = () => {
  //   setNotification(false);
  //   navigate('/admin/dashboard');
  //   setLocalStorageItem('showSubscriptionPrompt', true);
  // };

  const getSubscriptionStatusMessage = (subscriptionValue, expiryDate) => {
    let status;
    const currentDate = new Date();
    const graceDate = new Date(subscriptionValue?.grace_date);
    if (subscriptionValue?.status) {
      if (subscriptionValue?.status === 'cancelled') {
        status = 'Subscription cancelled';
      } else if (graceDate >= currentDate && subscriptionValue?.status === 'expired') {
        status = 'Subscription expires extended';
      } else if ((currentDate > graceDate && subscriptionValue?.status === 'expired')
        || (subscriptionValue?.status === 'expired' && !Object.keys(subscriptionValue).includes('grace_date'))) {
        status = 'Subscription expired';
      } else if (expiryDate === 3 && subscriptionValue?.status === 'active') {
        status = 'Subscription expires soon';
      }
    }
    return status;
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('data'));
    const subscriptionValue = data?.user?.company?.subscription;
    const expiryDate = getDaysBetweenDates(subscriptionValue?.end_date, new Date());
    const showSubscription = getLocalStorageItem('showSubscriptionPrompt');
    if (!data) {
      navigate('/login');
      dispatch(resetAllSlices());
    } else {
      setUser(data.user);
      getTotal();
    }
    const statusMessage = getSubscriptionStatusMessage(subscriptionValue, expiryDate);
    if (statusMessage && !showSubscription) {
      // setNotification(true);
      setSubscriptionStatus(statusMessage);
    } else {
      // setNotification(false);
    }
  }, []);

  return (
    <div className="dashboard-wrap">
      {/* <ModalBox
        status={notification}
        closeModal={closeModal}
        modalView={{
          content: <SubscriptionRenewal
            close={setNotification}
            errorMessage={subscriptionStatus}
          />,
        }}
      /> */}
      <div className="count-cards">
        <h1>{t('welcomeBack')} {user?.name}</h1>
        <Count count={count} />
      </div>
      <div className="dashboard-content">
        <Location />
        <div className="schedules-devices">
          <Schedules />
          <StorageStats />
        </div>
        <div className="display-content">
          <DisplayStatus />
          <DisplayOs />
        </div>
        <div className="display-content">
          {/* <ScheduleStatus /> */}
          <RecentActivity />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
