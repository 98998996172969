import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import key from '../../../assets/icons/key.png';
import { Input1 } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import auth from '../../../utils/api/auth';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function PassWordAutoGenerated(props) {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [disable, setDisable] = useState(true);
  const [password, setPassword] = useState();
  const [passwordValue, setPasswordValue] = useState();
  const handleChange = (name, changeValue) => {
    console.log(name, changeValue);
    setPasswordValue((prev) => ({
      ...prev,
      [name]: changeValue,
    }));
  };
  const submit = (type) => {
    // console.log(type, 'dfkbjvdhj');
    if (type === 'auto') {
      auth.passwordGenerate({ passwordType: 'auto_generate_password' })
        .then((res) => {
          setUploading('loading');
          console.log(res);
          setPassword(res?.data);
        })
        .catch((error) => {
          dispatch(setErrorNotification(error?.response?.data));
        });
    }
    if (type === 'continue') {
      props?.formStage(4);
    }
    if (type === 'manual') {
      setUploading(true);
      const value = {
        passwordType: 'manual_password',
        password: passwordValue.confirmPassword,
      };
      auth.passwordGenerate(value)
        .then(() => {
          setUploading(false);
          props?.formStage(4);
        })
        .catch((error) => {
          setUploading(false);
          dispatch(setErrorNotification(error?.response?.data));
        });
    }
  };
  useEffect(() => {
    const { newPassword, confirmPassword } = passwordValue || {};
    if (newPassword && newPassword === confirmPassword) {
      console.log('Passwords match');
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [passwordValue]);
  return (
    <div className="auto-generation-container">
      {!password && (
        <div className="password-from-container">
          <div className="form-header">
            <h3>Set Password</h3>
            <p>Enter your password for login</p>
            <div
              className="auto-password-btn"
              onClick={() => submit('auto')}
              role="presentation"
            >
              <div className="btn-container">
                <img src={key} alt="key" className="password-icon" />
                <p>Autogenerate Password</p>
              </div>
            </div>
            <div className="mid-password-line">
              <div className="line" />
              <span className="mid-password-text">OR</span>
              <div className="line" />
            </div>
          </div>
          <div className="password-main-container">
            <div className="input-box">
              <Input1
                label="New Password"
                type="password"
                name="newPassword"
                change={handleChange}
                value={passwordValue?.newPassword}
              />
            </div>
            <div className="input-box">
              <Input1
                label="Confirm Password"
                type="password"
                name="confirmPassword"
                change={handleChange}
                value={passwordValue?.confirmPassword}
              />
            </div>
            <div className="password-submit-btn">
              <Button
                label="Continue"
                classes={uploading ? 'success-button loading-btn' : 'success-button'}
                loading={uploading}
                disabled={disable}
                click={() => submit('manual')}
              />
            </div>
            <div className="form-footer">
              <span>Need further assistance?  </span>
              <span className="contact-support">Contact Support</span>
            </div>
          </div>
        </div>
      )}
      {password && (
        <div className="password-view-container">
          <div className="password-details-container">
            <div className="details-header">
              <h3>Autogenerated Password</h3>
              <span>Enter your password for login</span>
            </div>
            <div className="password-field">
              <Input1
                label="Password"
                type="password"
                name="password"
                value={password?.password}
                readonly
              />
            </div>
            <div className="button-field">
              <Button
                label="Continue"
                classes="success-button"
                click={() => submit('continue')}
              />
            </div>
            <div className="contact-container">
              <span>Need further assistance?  </span>
              <span className="contact-btn">Contact Support</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
PassWordAutoGenerated.propTypes = {
  formStage: PropTypes.func.isRequired,
};
export default PassWordAutoGenerated;
