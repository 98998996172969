import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Input1 } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import config from '../../../utils/api/config';
import { getLocalStorageItem, removeLocalStorageItem } from '../../../utils/helpers';

function ServerConfig(props) {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [buttonName, setButtonName] = useState('connect');
  const [disable, setDisable] = useState(true);
  const [configValue, setConfigValue] = useState();
  const [completedStep, setCompletedStep] = useState(1);
  const [selectedStorage, setSelectedStorage] = useState('');
  const value = true;
  const handleStorageChange = (event) => {
    setConfigValue((prev) => ({
      ...prev,
      checkBox: event.target.value,
    }));
  };
  const handleChange = (name, changeValue) => {
    setConfigValue((prev) => ({
      ...prev,
      [name]: changeValue,
    }));
  };
  const submit = (type) => {
    if (type === 'database' && buttonName === 'connect') {
      setUploading(true);
      setTimeout(() => {
        config.dataBaseConnection({ mongodbUrl: configValue?.MongoDBUrl })
          .then(() => {
            setUploading(false);
            setButtonName('continue');
          })
          .catch((error) => {
            setUploading(false);
            dispatch(setErrorNotification(error?.response?.data));
          });
      }, 3000);
    }
    if (type === 'database' && buttonName === 'continue') {
      const licenseDetails = getLocalStorageItem('licenseDetails');
      setUploading(true);
      setTimeout(() => {
        config.companyCreation(licenseDetails)
          .then(() => {
            setDisable(true);
            setUploading(false);
            if (completedStep < 4) {
              setCompletedStep(completedStep + 1);
              setSelectedStorage('value');
              removeLocalStorageItem('licenseDetails');
            }
          })
          .catch((error) => {
            setUploading(false);
            dispatch(setErrorNotification(error?.response?.data));
          });
      }, 8000);
    }
    if (configValue?.checkBox === 'local') {
      // setCompletedStep(2);
      setSelectedStorage(1);
      setDisable(true);
    }
    if (configValue?.checkBox === 'cloud') {
      setSelectedStorage(2);
      setDisable(true);
    }
    if (type === 'storageComplete') {
      setUploading(true);
      let storageType;
      if (configValue?.localPath) {
        storageType = {
          storageType: 'local_storage',
          localStoragePath: configValue?.localPath,
        };
      } else {
        storageType = {
          storageType: 'cloud_storage',
          bucketName: configValue?.BucketName,
          region: configValue?.Region,
          accessKey: configValue?.AccessKey,
          accessSecret: configValue?.AccessSecret,
        };
      }
      config.StorageConnection(storageType)
        .then((res) => {
          setSelectedStorage(0);
          console.log(res);
          setUploading(false);
          // setTimeout(() => {
          if (completedStep < 4) {
            setCompletedStep(completedStep + 1);
          }
          // }, 5000);
        })
        .catch((error) => {
          setUploading(false);
          if (configValue?.localPath) {
            setSelectedStorage(1);
          } else {
            setSelectedStorage(2);
          }
          dispatch(setErrorNotification(error?.response?.data));
        });
      // setCompletedStep(3);
    }
    if (type === 'Smtp') {
      setSelectedStorage(0);
      const Smtp = {
        host: configValue?.Hostname,
        port: configValue?.Port,
        mailId: configValue?.Email,
        username: configValue?.Username,
        password: configValue?.Password,
      };
      config.SMTPConnection(Smtp)
        .then(() => {
          setUploading(false);
          if (completedStep < 4) {
            setCompletedStep(completedStep + 1);
          }
        })
        .catch((error) => {
          setUploading(false);
          dispatch(setErrorNotification(error?.response?.data));
        });
    }
    if (type === 'skip') {
      props?.formStage(5);
    }
    if (type === 'openSearch') {
      const openSearch = {
        domainUrl: configValue?.openSearchUrl,
        username: configValue?.SearchName,
        password: configValue?.SearchPassword,
      };
      config.OpenSearchConnection(openSearch)
        .then(() => {
          setSelectedStorage(0);
          setUploading(false);
          props?.formStage(5);
        })
        .catch((error) => {
          setUploading(false);
          dispatch(setErrorNotification(error?.response?.data));
        });
    }
  };
  useEffect(() => {
    if (
      configValue?.AccessSecret
      && configValue?.AccessKey?.length
      && configValue?.Region?.length
      && configValue?.BucketName?.length
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    if (
      configValue?.SearchPassword?.length
      && configValue?.SearchName?.length
      && configValue?.openSearchUrl?.length
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    if (configValue?.checkBox) {
      setDisable(false);
    }
  }, [configValue]);
  useEffect(() => {
    if (configValue?.MongoDBUrl?.length || configValue?.localPath?.length) {
      console.log('dfkjvndk');
      setDisable(false);
    } else {
      console.log('23');
      setDisable(true);
    }
  }, [configValue?.MongoDBUrl, configValue?.localPath]);

  return (
    <div className="server-config-main-container">
      <div className="config-container">
        <div className="progress-sidebar">
          <div className={`progress-container ${completedStep >= 1 ? 'completed' : ''}`}>
            <span className="progress-number">
              {completedStep > 1 ? '✓' : '1'}
            </span>
            <span className="progress-content">Database Configuration</span>
            <span className="progress-line" />
          </div>
          <div className={`progress-container ${completedStep >= 2 ? 'completed' : ''}`}>
            <span className="progress-number">
              {completedStep > 2 ? '✓' : '2'}
            </span>
            <span className="progress-content">Storage Configuration</span>
            <span className="progress-line" />
          </div>
          <div className={`progress-container ${completedStep >= 3 ? 'completed' : ''}`}>
            <span className="progress-number">
              {completedStep > 3 ? '✓' : '3'}
            </span>
            <span className="progress-content">SMTP Configuration</span>
            <span className="progress-line" />
          </div>
          <div className={`progress-container ${completedStep >= 4 ? 'completed' : ''}`}>
            <span className="progress-number">
              {completedStep === 4 ? '4' : ''}
            </span>
            <span className="progress-content">Open search Configuration</span>
          </div>
        </div>
        <div className="main-form">
          {completedStep === 1 && (
            <div className="form1">
              <div className="form-header">
                <h3>Database Configuration!</h3>
                <span>Enter Database configuration details</span>
              </div>
              <div className="form-input-container">
                <div className="db-config-input">
                  <Input1
                    label="MongoDBUrl"
                    name="MongoDBUrl"
                    change={handleChange}
                    value={configValue?.MongoDBUrl}
                  />
                </div>
                <div className="example-db-url">Example: mongodb://localhost:27017/</div>
                <div className="db-form-btn">
                  <Button
                    label={buttonName}
                    click={() => submit('database')}
                    classes={uploading ? 'success-button loading-btn' : 'success-button'}
                    loading={uploading}
                    disabled={disable}
                  />
                </div>
              </div>
            </div>
          )}
          {selectedStorage === 'value' && (
            <div className="form2">
              <div className="form-header">
                <h3>Storage Configuration!</h3>
                <span>Enter Storage configuration details</span>
              </div>
              <div className="storage-type">
                <div className="cloud-storage">
                  <div className="cloud-checkbox">
                    <input
                      type="radio"
                      className="custom-checkbox"
                      name="storage-type"
                      value="cloud"
                      checked={configValue?.checkBox === 'cloud'}
                      onChange={handleStorageChange}
                    />
                    <p>Recommended</p>
                  </div>
                  <h4>Cloud Storage <span className="sub-content">(AWS S3)</span></h4>
                </div>
                <div className="local-storage">
                  <div className="local-checkbox">
                    <input
                      type="radio"
                      className="custom-checkbox"
                      name="storage-type"
                      value="local"
                      checked={configValue?.checkBox === 'local'}
                      onChange={handleStorageChange}
                    />
                  </div>
                  <h4>Local Storage</h4>
                </div>
              </div>
              <div className="storage-btn">
                <Button
                  label="Continue"
                  click={() => submit('localStorage')}
                  classes="success-button"
                  disabled={disable}
                  arrow={value}
                />
              </div>
            </div>
          )}
          {selectedStorage === 1 && (
            <div className="local-storage-form">
              <div className="form-header">
                <h3>Storage Configuration!</h3>
                <span>Enter Storage configuration details</span>
              </div>
              <div className="local-storage-form-content">
                <div className="local-form-input">
                  <Input1
                    label="Storage Path"
                    name="localPath"
                    value={configValue?.localPath}
                    change={handleChange}
                    type="contentUpload"
                  />
                </div>
                <div className="local-from-button">
                  <Button
                    label="Continue"
                    click={() => submit('storageComplete')}
                    classes={uploading ? 'success-button loading-btn' : 'success-button'}
                    loading={uploading}
                    arrow={value}
                    disabled={disable}
                  />
                </div>
              </div>
            </div>
          )}
          {selectedStorage === 2 && (
            <div className="cloud-storage-form">
              <div className="form-header">
                <h3>Storage Configuration!</h3>
                <span>Enter Storage configuration details</span>
              </div>
              <div className="cloud-storage-form-content">
                <div className="cloud-form-input">
                  <Input1
                    label="AWS Bucket Name"
                    name="BucketName"
                    value={configValue?.BucketName}
                    change={handleChange}
                  />
                </div>
                <div className="cloud-form-input">
                  <Input1
                    label="AWS Region"
                    name="Region"
                    value={configValue?.Region}
                    change={handleChange}
                  />
                </div>
                <div className="cloud-form-input">
                  <Input1
                    label="AWS Access Key"
                    name="AccessKey"
                    value={configValue?.AccessKey}
                    change={handleChange}
                  />
                </div>
                <div className="cloud-form-input">
                  <Input1
                    label="AWS Access Secret"
                    name="AccessSecret"
                    value={configValue?.AccessSecret}
                    change={handleChange}
                  />
                </div>
                <div className="cloud-from-button">
                  <Button
                    label="Continue"
                    click={() => submit('storageComplete')}
                    classes={uploading ? 'success-button loading-btn' : 'success-button'}
                    loading={uploading}
                    arrow={value}
                    disabled={disable}
                  />
                </div>
              </div>
            </div>
          )}
          {completedStep === 3 && (
            <div className="cloud-storage-form">
              <div className="form-header">
                <h3>SMTP Configuration!</h3>
                <span>Enter SMTP configuration details</span>
              </div>
              <div className="cloud-storage-form-content">
                <div className="cloud-form-input">
                  <Input1
                    label="Hostname"
                    name="Hostname"
                    value={configValue?.Hostname}
                    change={handleChange}
                  />
                </div>
                <div className="cloud-form-input">
                  <Input1
                    label="Port"
                    name="Port"
                    value={configValue?.Port}
                    change={handleChange}
                  />
                </div>
                <div className="cloud-form-input">
                  <Input1
                    label="Username"
                    name="Username"
                    value={configValue?.Username}
                    change={handleChange}
                  />
                </div>
                <div className="cloud-form-input">
                  <Input1
                    type="password"
                    label="Password"
                    name="Password"
                    value={configValue?.Password}
                    change={handleChange}
                  />
                </div>
                <div className="cloud-form-input">
                  <Input1
                    label="From Email"
                    name="Email"
                    value={configValue?.Email}
                    change={handleChange}
                  />
                </div>
                <div className="smtp-from-button">
                  <div className="smtp-btn">
                    <Button
                      label="Continue"
                      click={() => submit('Smtp')}
                      classes={uploading ? 'success-button loading-btn' : 'success-button'}
                      loading={uploading}
                      arrow={value}
                      disabled={disable}
                    />
                  </div>
                  <div className="skip" onClick={() => submit('skip')} role="presentation">
                    <span>Skip</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {completedStep === 4 && (
            <div className="open-search-form">
              <div className="form-header">
                <h3>Open search Configuration!</h3>
                <span>Enter Open search details</span>
              </div>
              <div className="open-search-form-content">
                <div className="cloud-form-input">
                  <Input1
                    label="URl"
                    name="openSearchUrl"
                    value={configValue?.openSearchUrl}
                    change={handleChange}
                  />
                </div>
                <div className="search-form-input">
                  <Input1
                    label="Username"
                    name="SearchName"
                    value={configValue?.SearchName}
                    change={handleChange}
                  />
                </div>
                <div className="search-form-input">
                  <Input1
                    type="password"
                    label="Password"
                    name="SearchPassword"
                    value={configValue?.SearchPassword}
                    change={handleChange}
                  />
                </div>
                <div className="search-from-button">
                  <div className="open-search-footer">
                    <div className="open-search-btn">
                      <Button
                        label="Continue"
                        click={() => submit('openSearch')}
                        classes={uploading ? 'success-button loading-btn' : 'success-button'}
                        loading={uploading}
                        arrow={value}
                        disabled={disable}
                      />
                    </div>
                    <div className="skip" onClick={() => submit('skip')} role="presentation">
                      <span>Skip</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

ServerConfig.propTypes = {
  formStage: PropTypes.func.isRequired,
};

export default ServerConfig;
