import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

import DataTable from '../Table';

import TopContent from '../../shared/component/TopContent';
import DeleteModal from '../../shared/component/DeleteModal';
import DropDownMenu from '../../shared/component/DropDownMenu';
import ModalBox from '../../shared/component/ModalBox';
import {
  MoreMenuIcon,
  ViewIcon,
  ListMenuIcon,
  ActiveListMenu,
  GridViewIcon,
  ActiveGridViewIcon,
} from '../../shared/component/svgIcon';

import devices from '../../utils/api/devices';
import { checkFeatures, checkPermission, statusColor } from '../../utils/helpers';

import {
  fetchDisplaySignageList,
  setFilter,
  setTableFilter,
  deleteDisplay,
} from '../../redux/slices/DisplaySignageSlice';
import { fetchUpdateDevices } from '../../redux/slices/OtaUpdateSlice';

import DeviceSchedule from './components/DeviceSchedule';
import DeviceInfo from './components/DeviceInfo';
import DeviceStats from './components/DeviceStats';
import OtaUpdate from './components/otaUpdate';
import ActivityLog from './components/ActivityLog';

import exportCSV from '../Global/exportCSV';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';
import CardView from './components/CardView';
import height from '../../utils/size-variables';
import Toggle from '../Schedule/components/ToggleButton';
import DisplaySignageAddNew from './components/DisplaySignageAddNew';
import DisplaySignageEditNew from './components/DisplaySignageEditNew';
import Success from '../../shared/component/Success';

function DisplaySignage() {
  const navigate = useNavigate();
  const { page } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const deviceStatusList = [
    { value: 'active', label: 'Active' },
    { value: 'inActive', label: 'In-Active' },
  ];

  const {
    displaySignage,
    pageCount,
    totalPageCount,
    status,
    filter,
    tableFilter,
    totalDataCount,
  } = useSelector((state) => state.display);

  const tabPages = [
    {
      key: 'list',
      icon: <ListMenuIcon />,
      activeIcon: <ActiveListMenu />,
      enable: checkPermission('getDisplaySignage'),
    },
    {
      key: 'grid',
      icon: <GridViewIcon />,
      activeIcon: <ActiveGridViewIcon />,
      enable: checkPermission('getDisplaySignage'),
    },
  ];

  const {
    updateDevices,
    status: updateDeviceStatus,
  } = useSelector((state) => state.otaUpdate);

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deviceId, setDeviceId] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const [modalView, setModalView] = useState();
  const [deviceUpdate, setDeviceUpdate] = useState(false);
  const [updatePopup, setUpdatePopup] = useState(false);
  const [notification, setNotification] = useState(false);

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});

  const getDisplaySignage = (counts, search) => {
    if (status === 'succeeded') {
      dispatch(fetchDisplaySignageList({ page: counts, limit: 20, ...search }));
    }
  };

  const handleScroll = () => {
    getDisplaySignage(pageCount + 1, filter);
  };

  const getDeleteDisplaySignageId = (id) => {
    setDeviceId(id);
    setOpenDeleteModal(true);
  };

  const deleteDisplaySignage = () => {
    devices.deleteDevice(deviceId)
      .then(() => {
        dispatch(deleteDisplay(deviceId));
        setDeviceId(' ');
        setOpenDeleteModal(false);
      }).catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const viewDisplaySignage = (id) => {
    navigate(`../view/${id}`);
  };

  const viewSchedulesInDevice = (id) => {
    setModalView({
      title: t('schedules'),
      content: <DeviceSchedule deviceId={id} />,
    });
    setOpenPopup(true);
  };

  const viewLogsInDevice = (id) => {
    setModalView({
      title: t('activityLog'),
      content: <ActivityLog id={id} />,
    });
    setOpenPopup(true);
  };

  const viewDeviceStats = (device) => {
    setModalView({
      title: device?.name,
      content: <DeviceStats deviceId={device?.id} />,
    });
    setOpenPopup(true);
  };

  const infoDisplaySignage = (singleSignage) => {
    setModalView({
      title: t('deviceInfo'),
      content: <DeviceInfo data={singleSignage} />,
    });
    setOpenPopup(true);
  };

  const submit = (message) => {
    setNotification(true);
    setModalView({
      title: '',
      content: <Success message={message} />,
    });
    setTimeout(() => {
      setNotification(false);
      setOpenPopup(false);
    }, 3000);
  };

  const GridView = (url) => {
    navigate(url);
  };
  const editDisplaySignage = (displaySignageData) => {
    setModalView({
      title: t('editDisplaySignage'),
      content: <DisplaySignageEditNew
        close={() => setOpenPopup(false)}
        submitDisplay={submit}
        selectValue={displaySignageData}
      />,
    });
    setOpenPopup(true);
  };

  const actions = [
    {
      name: t('view'),
      url: '',
      function: viewDisplaySignage,
      key: 'view',
      disabled: !checkPermission('getDisplaySignage') || !checkFeatures('device'),
    },
    {
      name: t('edit'),
      url: '',
      function: editDisplaySignage,
      key: 'view',
      disabled: !checkPermission('editDisplaySignage') || !checkFeatures('device'),
      sendFullDetails: true,
    },
    {
      name: t('schedules'),
      url: '',
      function: viewSchedulesInDevice,
      key: 'view',
      disabled: !checkPermission('viewScheduleDisplaySignage') || !checkFeatures('device'),
    },
    {
      name: t('activityLog'),
      url: '',
      function: viewLogsInDevice,
      key: 'view',
      disabled: !checkPermission('viewActiveLogsDisplaySignage') || !checkFeatures('device'),
    },
    {
      name: t('storage'),
      url: '',
      function: viewDeviceStats,
      key: 'view',
      disabled: !checkPermission('viewStorageDisplaySignage') || !checkFeatures('device'),
      sendFullDetails: true,
    },
    {
      name: t('delete'),
      url: '',
      function: getDeleteDisplaySignageId,
      key: 'delete',
      disabled: !checkPermission('deleteDisplaySignage') || !checkFeatures('device'),
    },
  ];

  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('storeInfo'),
      accessorKey: 'store',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => row.original.store_name,
    },
    {
      header: t('deviceName'),
      accessorKey: 'name',
      Cell: ({ row }) => (
        <div className="device-status">
          <span>{row.original.name}</span>
          <Tooltip title="Info">
            <div
              className="tooltip-container"
              role="presentation"
              onClick={() => infoDisplaySignage(row.original)}
            >
              <ViewIcon className="status-view-icon" />
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      header: t('deviceOs'),
      accessorKey: 'hardware',
    },
    {
      header: t('playerVersion'),
      accessorKey: 'player_app_version',
      Cell: ({ row }) => (
        <div className="player-app-version">
          <span>{row?.original?.player_app_version}</span>
          <div className="player-app-status">
            {row?.original?.player_app_status === 'pending'
              && (
                <div className="each-status pending">
                  <span>Updating</span>
                  <span>{row?.original?.new_player_app_version}</span>
                </div>
              )}
            {row?.original?.player_app_status === 'Latest'
              && (
                <div className="each-status latest">
                  <span>Latest</span>
                  <span>{row?.original?.new_player_app_version}</span>
                </div>
              )}
          </div>
        </div>
      ),
    },
    // {
    //   header: t('Size'),
    //   accessorKey: 'size',
    // },
    {
      header: t('orientation'),
      accessorKey: 'orientation',
    },
    // {
    //   header: t('Resolution'),
    //   accessorKey: 'resolution',
    // },
    // {
    //   header: t('signageInfo'),
    //   accessorKey: 'orientation',
    //   muiFilterTextFieldProps: { placeholder: 'Filter by Information' },
    //   filterFn: 'commonFIlterFn',
    //   Cell: ({ row }) => (
    //     <div className="array_col">
    //       {
    //         row.original.signage_information.map((val) => (
    //           <p>{val.name} : <b>{val.value}</b></p>
    //         ))
    //       }
    //     </div>
    //   ),
    // },
    {
      header: '',
      accessorKey: 'id',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 50,
      Cell: ({ row }) => (
        <DropDownMenu
          action={actions}
          icon={<MoreMenuIcon />}
          value={row?.original}
          dropdown="tableForm"
          tooltip=""
        />
      ),
    },
    {
      header: t('status'),
      accessorKey: 'status',
      filterVariant: 'select',
      filterSelectOptions: deviceStatusList,
      enableSorting: false,
      enableColumnFilter: false,
      enableHiding: true,
      enableResizing: false,
      size: 125,
      Cell: ({ row }) => {
        const values = row.original.lastPollTime ? 'Active' : 'Inactive';
        return (
          <div className="device-status">
            <Box
              component="span"
              sx={() => ({
                backgroundColor: statusColor(values).backgroundColor,
                borderRadius: '0.25rem',
                color: statusColor(values).color,
                p: '0.25rem',
              })}
            >
              <span>{t(values)}</span>
            </Box>
            {values === 'Inactive'
              && (
                <Tooltip title={row.original.timeDifference}>
                  <div className="tooltip-container">
                    <ViewIcon className="status-view-icon" />
                  </div>
                </Tooltip>
              )}
          </div>
        );
      },
    },
  ];

  const addPlaylist = () => {
    // navigate('../add');
    setModalView({
      title: t('createNewDisplaySignage'),
      content: <DisplaySignageAddNew close={() => setOpenPopup(false)} submitDisplay={submit} />,
    });
    setOpenPopup(true);
  };

  const exportData = () => {
    const columnHeaders = ['name', 'storeName', 'hardware', 'player_app_version', 'orientation'];

    // Filter the hidden column
    const filteredHeaders = columnHeaders.filter((header) => columnVisibility[header] !== false);

    // API call for export CSV
    devices.deviceDataExport({ fields: filteredHeaders, ...filter })
      .then((res) => {
        // Function to export as CSV file
        exportCSV(filteredHeaders, res?.data);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  // Toggle functionality between device and update_screen
  const checkForUpdates = () => {
    dispatch(fetchUpdateDevices());
    setDeviceUpdate(true);
  };

  const closeUpdatePopup = () => {
    setUpdatePopup(false);
  };

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilter(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getDisplaySignage(1, filteredValues);
  };

  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(tableFilter).length === 0) {
      getDisplaySignage(1);
    }
  }, []);

  // To open a popup for update button when selected list changes
  useEffect(() => {
    setUpdatePopup(true);
  }, [rowSelection]);

  return (
    <div className="content">
      <OtaUpdate
        rowSelection={Object.keys(rowSelection)}
        openPopup={Object.keys(rowSelection)?.length > 0 && updatePopup}
        setDeviceUpdate={setDeviceUpdate}
        setRowSelection={setRowSelection}
        closePopup={closeUpdatePopup}
      />
      <ModalBox
        status={openPopup}
        closeModal={setOpenPopup}
        modalView={modalView}
        notification={notification}
      />
      <div className="main-content">
        <TopContent
          label={t('signageDevices')}
          buttonClass="success-button display-signage-createbutton"
          button={checkPermission('addDisplaySignage') && checkFeatures('device')}
          buttonLabel={t('newDisplaySignage')}
          click={addPlaylist}
        />
        <DeleteModal
          status={openDeleteModal}
          closeModal={setOpenDeleteModal}
          okFunction={deleteDisplaySignage}
          buttonLabel={t('delete')}
          title={t('areYouSureYouWantToDeleteThisDisplaySignage?')}
          subTitle=<span> {t('thisWillDeleteThisDevicePermanently')} <br />{t('youCannotUndoThisAction.')}</span>
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area signage-table">

              {page === 'grid' && (
                <CardView
                  toolbarLeft={<Toggle button1={GridView} active={page} tabPages={tabPages} />}
                  actions={actions}
                />
              )}
              {page === 'list' && !deviceUpdate && (
                <DataTable
                  header={columns}
                  value={displaySignage}
                  onFilterOrSortingChange={onFilterOrSortingChange}
                  status={status}
                  totalPageCount={totalPageCount}
                  pageCount={pageCount}
                  scrollData={handleScroll}
                  totalDataCount={totalDataCount}
                  enableRowSelection={deviceUpdate}
                  rowSelection={[]}
                  setRowSelection={setRowSelection}
                  columnVisibility={columnVisibility}
                  setColumnVisibility={setColumnVisibility}
                  enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
                  height={height.onlyTableHeight}
                  exportButton={checkFeatures('device') && checkPermission('exportDisplaySignage')}
                  exportData={exportData}
                  newButton={checkFeatures('device') && checkPermission('otaUpdateDisplaySignage')}
                  newButtonLabel={t('checkForUpdates')}
                  newButtonClickEvent={checkForUpdates}
                  isFirstRender={isFirstRender}
                  keyValue="display"
                  showToolbarLeft
                  toolbarLeft={<Toggle button1={GridView} active={page} tabPages={tabPages} />}
                />
              )}
              {page === 'list' && deviceUpdate && (
                <DataTable
                  header={columns}
                  value={updateDevices}
                  onFilterOrSortingChange={() => { }}
                  status={updateDeviceStatus}
                  totalPageCount={totalPageCount}
                  pageCount={pageCount}
                  scrollData={handleScroll}
                  totalDataCount={totalDataCount}
                  enableRowSelection={deviceUpdate}
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                  columnVisibility={columnVisibility}
                  setColumnVisibility={setColumnVisibility}
                  enable={false}
                  height={height.onlyTableHeight}
                  newButton
                  newButtonLabel={t('deviceList')}
                  newButtonClickEvent={() => setDeviceUpdate(false)}
                  isFirstRender={isFirstRender}
                  keyValue="update"
                  disableFilter
                  disableSorting
                  showToolbarLeft
                  toolbarLeft={<Toggle button1={GridView} active={page} tabPages={tabPages} />}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
DisplaySignage.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string,
      player_app_version: PropTypes.string,
      player_app_status: PropTypes.string,
      new_player_app_version: PropTypes.string,
      lastPollTime: PropTypes.string,
      timeDifference: PropTypes.string,
    }),
  }),
};
DisplaySignage.defaultProps = {
  row: {},
};

export default DisplaySignage;
