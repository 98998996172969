import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import cookies from 'js-cookie';
import { Drawer, IconButton } from '@mui/material';
import { RightArrow, CloseIcon } from './svgIcon';
// import SubscriptionRenew from './SubscriptionRenewPrompt';
// import { getLocalStorageItem } from '../../utils/helpers';

function SideBar(props) {
  const { sidebarMenu, setSideBarMenu } = props;
  // const showSubscription = getLocalStorageItem('showSubscriptionPrompt');
  const navigate = useNavigate();
  const locate = useLocation();
  const currentPath = locate.pathname;
  const parts = currentPath.split('/');
  const slicedString = parts.slice(0, 3).join('/');
  const currentLanguageCode = cookies.get('i18next') || 'en';

  const [sidebarCollapse, setSidebarCollapse] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown'
      && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    props?.setDrawerOpen(open);
  };

  const handleClick = (navigateUrl) => {
    setSideBarMenu((prevValue) => prevValue.map((menu) => {
      const updatedSubMenus = menu.subMenus.map((subMenu) => {
        let updatedSubMenu;
        if (navigateUrl && navigateUrl.includes(subMenu.url)) {
          updatedSubMenu = {
            ...subMenu,
            activeMenu: true,
          };
        } else {
          updatedSubMenu = {
            ...subMenu,
            activeMenu: false,
          };
        }
        return updatedSubMenu;
      });
      return {
        ...menu,
        subMenus: updatedSubMenus,
      };
    }));
  };

  const handleAccordion = (id) => {
    setSidebarCollapse(false);
    setSideBarMenu((prevValue) => prevValue.map((menu) => {
      let finalMenu = menu;
      if (menu.id === id) {
        finalMenu = {
          ...menu,
          accordion: !menu.accordion,
        };
      }
      return finalMenu;
    }));
  };

  useEffect(() => {
    if (sidebarCollapse) {
      setSideBarMenu((prevValue) => prevValue.map((menu) => ({
        ...menu,
        accordion: false,
      })));
    } else {
      setSideBarMenu((prevValue) => prevValue.map((menu) => {
        let updatedMenu = menu;
        menu.subMenus.map((subMenu) => {
          if (slicedString.includes(subMenu.url)) {
            updatedMenu = {
              ...menu,
              accordion: true,
            };
          }
          return subMenu;
        });
        return updatedMenu;
      }));
    }
  }, [sidebarCollapse]);

  useEffect(() => {
    handleClick(slicedString);
    setSideBarMenu((prevValue) => prevValue.map((menu) => {
      let updatedMenu = menu;
      menu.subMenus.map((subMenu) => {
        if (slicedString.includes(subMenu.url)) {
          updatedMenu = {
            ...menu,
            accordion: true,
          };
        }
        return subMenu;
      });
      return updatedMenu;
    }));
  }, [locate]);
  return (
    <div>
      <Drawer
        anchor="left"
        open={props?.drawerOpen}
        onClose={toggleDrawer(false)}
        className="sidebar-drawer"
      >
        <div className="drawer-content">
          <div className="drawer-header">
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon color="#000" />
            </IconButton>
          </div>
          <div className="drawer-menu">
            <div className="sidebar-menu-wrap">
              {sidebarMenu.map((menu, indexValue) => {
                let finalMenu = <div />;
                if (menu.showAccordionArrow) {
                  finalMenu = (
                    <div className="sidebar-main-menu" key={menu.id}>
                      <div
                        className="accordion-wrap"
                        role="button"
                        tabIndex={indexValue}
                        onClick={() => handleAccordion(menu.id)}
                        onKeyPress={() => handleAccordion(menu.id)}
                      >
                        <div className="accordion-content">
                          <span>{React.cloneElement(menu.svgIcon)}</span>
                          <h1 style={{ display: sidebarCollapse ? 'none' : 'block' }}>
                            {menu?.name}
                          </h1>
                        </div>
                        <span
                          className="accordion"
                          style={{
                            '--rotate-accordion': menu.accordion
                              ? 'rotate(90deg)'
                              : 'rotate(0deg)',
                            display: sidebarCollapse ? 'none' : 'block',
                          }}
                        >
                          <RightArrow width="10px" height="10px" color="#8E8E8E" />
                        </span>
                      </div>
                      <div
                        className="sidebar-sub-menu"
                        style={{
                          height: menu.accordion
                            ? `${menu.subMenus.filter((val) => val.enable).length * 44.1 + 12}px`
                            : '0px',
                        }}
                      >
                        {menu.subMenus?.map(
                          (subMenu, index) => subMenu.enable && (
                            <div
                              className={`each-sub-menu ${subMenu.class}`}
                              role="button"
                              tabIndex={0}
                              onClick={() => {
                                props?.setDrawerOpen(false);
                                navigate(subMenu.url);
                              }}
                              onKeyPress={() => navigate(subMenu.url)}
                              style={{ marginTop: index === 0 ? '12px' : '0' }}
                              key={subMenu.url}
                            >
                              <Link to={subMenu.url}>
                                <div
                                  className="subMenu-point"
                                  style={{ '--active-menu-color': subMenu.activeMenu ? '#30A84B' : '#5C646D' }}
                                />
                                <p
                                  style={{
                                    '--active-menu-color': subMenu.activeMenu ? '#30A84B' : '#5C646D',
                                    display: sidebarCollapse ? 'none' : 'block',
                                  }}
                                >
                                  {subMenu?.name}
                                </p>
                              </Link>
                              <div
                                style={{ '--background-width': subMenu.activeMenu ? '100%' : '0%' }}
                                className="absolute-background"
                              />
                            </div>
                          ),
                        )}
                      </div>
                    </div>
                  );
                } else if (!menu.subMenus.showAccordionArrow) {
                  finalMenu = (
                    <div className="single-sub-menu" key={menu.id}>
                      <div
                        className={`each-sub-menu ${menu.subMenus[0].class}`}
                        role="button"
                        tabIndex={indexValue}
                        onClick={() => {
                          props?.setDrawerOpen(false);
                          navigate(menu.subMenus[0].url);
                        }}
                        onKeyPress={() => navigate(menu.subMenus[0].url)}
                      >
                        <Link to={menu.subMenus[0].url}>
                          {menu.subMenus[0]?.svgIcon && (
                            <span>
                              {React.cloneElement(
                                menu.subMenus[0]?.svgIcon,
                                { color: menu.subMenus[0]?.activeMenu ? '#30A84B' : '#5C646D' },
                              )}
                            </span>
                          )}
                          <p
                            style={{
                              '--active-menu-color': menu.subMenus[0].activeMenu ? '#30A84B' : '#8E8E8E',
                              opacity: sidebarCollapse ? '0' : '1',
                            }}
                          >
                            {menu.subMenus[0]?.name}
                          </p>
                        </Link>
                        <div
                          style={{ '--background-width': menu.subMenus[0].activeMenu ? '100%' : '0%' }}
                          className="absolute-background"
                        />
                      </div>
                    </div>
                  );
                }
                return menu.enable && finalMenu;
              })}
            </div>

          </div>
        </div>
      </Drawer>
      <div
        className="sidebar-wrap"
        style={{
          width: sidebarCollapse ? '70px' : '100%',
          minWidth: sidebarCollapse ? '70px' : '275px',
        }}
      >
        <div className="sidebar-menu-wrap">
          {sidebarMenu.map((menu, indexValue) => {
            let finalMenu = <div />;
            if (menu.showAccordionArrow) {
              finalMenu = (
                <div className="sidebar-main-menu" key={menu.id}>
                  <div
                    className="accordion-wrap"
                    role="button"
                    tabIndex={indexValue}
                    onClick={() => handleAccordion(menu.id)}
                    onKeyPress={() => handleAccordion(menu.id)}
                  >
                    <div className="accordion-content">
                      <span>{React.cloneElement(menu.svgIcon)}</span>
                      <h1 style={{ display: sidebarCollapse ? 'none' : 'block' }}>
                        {menu?.name}
                      </h1>
                    </div>
                    <span
                      className="accordion"
                      style={{
                        '--rotate-accordion': menu.accordion
                          ? 'rotate(90deg)'
                          : 'rotate(0deg)',
                        display: sidebarCollapse ? 'none' : 'block',
                      }}
                    >
                      <RightArrow width="10px" height="10px" color="#8E8E8E" />
                    </span>
                  </div>
                  <div
                    className="sidebar-sub-menu"
                    style={{
                      height: menu.accordion
                        ? `${menu.subMenus.filter((val) => val.enable).length * 44.1 + 12}px`
                        : '0px',
                    }}
                  >
                    {menu.subMenus?.map(
                      (subMenu, index) => subMenu.enable && (
                        <div
                          className={`each-sub-menu ${subMenu.class}`}
                          role="button"
                          tabIndex={0}
                          onClick={() => navigate(subMenu.url)}
                          onKeyPress={() => navigate(subMenu.url)}
                          style={{ marginTop: index === 0 ? '12px' : '0' }}
                          key={subMenu.url}
                        >
                          <Link to={subMenu.url}>
                            <div
                              className="subMenu-point"
                              style={{ '--active-menu-color': subMenu.activeMenu ? '#30A84B' : '#5C646D' }}
                            />
                            <p
                              style={{
                                '--active-menu-color': subMenu.activeMenu ? '#30A84B' : '#5C646D',
                                display: sidebarCollapse ? 'none' : 'block',
                              }}
                            >
                              {subMenu?.name}
                            </p>
                          </Link>
                          <div
                            style={{ '--background-width': subMenu.activeMenu ? '100%' : '0%' }}
                            className="absolute-background"
                          />
                        </div>
                      ),
                    )}
                  </div>
                </div>
              );
            } else if (!menu.subMenus.showAccordionArrow) {
              finalMenu = (
                <div className="single-sub-menu" key={menu.id}>
                  <div
                    className={`each-sub-menu ${menu.subMenus[0].class}`}
                    role="button"
                    tabIndex={indexValue}
                    onClick={() => navigate(menu.subMenus[0].url)}
                    onKeyPress={() => navigate(menu.subMenus[0].url)}
                  >
                    <Link to={menu.subMenus[0].url}>
                      {menu.subMenus[0]?.svgIcon && (
                        <span>
                          {React.cloneElement(
                            menu.subMenus[0]?.svgIcon,
                            { color: menu.subMenus[0]?.activeMenu ? '#30A84B' : '#5C646D' },
                          )}
                        </span>
                      )}
                      <p
                        style={{
                          '--active-menu-color': menu.subMenus[0].activeMenu ? '#30A84B' : '#8E8E8E',
                          opacity: sidebarCollapse ? '0' : '1',
                        }}
                      >
                        {menu.subMenus[0]?.name}
                      </p>
                    </Link>
                    <div
                      style={{ '--background-width': menu.subMenus[0].activeMenu ? '100%' : '0%' }}
                      className="absolute-background"
                    />
                  </div>
                </div>
              );
            }
            return menu.enable && finalMenu;
          })}
          {/* {(showSubscription && !sidebarCollapse)
            && (<SubscriptionRenew />)} */}
        </div>
        <div
          className={currentLanguageCode === 'ar' ? 'toggle-arrow-wrap-ar' : 'toggle-arrow-wrap'}
          role="presentation"
          style={{
            '--rotate-arrow': sidebarCollapse ? 'rotate(0deg)' : 'rotate(180deg)',
            '--rotate-arrow-ar': sidebarCollapse ? 'rotate(180deg)' : 'rotate(0deg)',
            '--toggle-opacity': sidebarCollapse ? '1' : '0',
          }}
          onClick={() => setSidebarCollapse(!sidebarCollapse)}
          onKeyPress={() => setSidebarCollapse(!sidebarCollapse)}
        >
          <span className="toggle-arrow">
            <RightArrow width="8px" height="8px" color="#212B36" strokeWidth="2.50" />
          </span>
          <span className="toggle-arrow-hover">
            <RightArrow width="8px" height="8px" color="#fff" strokeWidth="2.50" />
          </span>
        </div>
      </div>
    </div>
  );
}

SideBar.propTypes = {
  sidebarMenu: PropTypes.arrayOf().isRequired,
  setSideBarMenu: PropTypes.func.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  setDrawerOpen: PropTypes.func.isRequired,
};

export default SideBar;
