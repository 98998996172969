import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import DataTable from '../Table';

import TopContent from '../../shared/component/TopContent';
import DeleteModal from '../../shared/component/DeleteModal';
import DropDownMenu from '../../shared/component/DropDownMenu';
import PopupModal from '../../shared/component/PopupModel';

import playlistApi from '../../utils/api/playlist';

import {
  fetchPlaylistList,
  setFilter,
  setTableFilter,
  deletePlaylist,
} from '../../redux/slices/PlaylistSlice';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';
import {
  checkPermission,
  convertBytes,
  changeDateTimeFormat,
  checkFeatures,
} from '../../utils/helpers';
import exportCSV from '../Global/exportCSV';
import { MoreMenuIcon } from '../../shared/component/svgIcon';
import height from '../../utils/size-variables';

function PlayList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    playlist,
    pageCount,
    totalPageCount,
    status,
    filter,
    tableFilter,
    totalDataCount,
  } = useSelector((state) => state.playlist);

  const [isFirstRender, setIsFirstRender] = useState(true);

  const [playListId, setPlaylistId] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});

  const getPlaylists = (counts, search = {}) => {
    if (status === 'succeeded') {
      dispatch(fetchPlaylistList({ page: counts, limit: 20, ...search }));
    }
  };

  const handleScroll = () => {
    getPlaylists(pageCount + 1, filter);
  };

  const getDeletePlaylistId = (id) => {
    setPlaylistId(id);
    setOpenDeleteModal(true);
  };

  const deletePlaylistById = () => {
    playlistApi.deletePlaylist(playListId)
      .then(() => {
        dispatch(deletePlaylist(playListId));
        setPlaylistId(' ');
        setOpenDeleteModal(false);
      }).catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const viewPlaylist = (id) => {
    navigate(`view/${id}`);
  };
  const actions = [
    {
      name: t('view'),
      url: '',
      function: viewPlaylist,
      key: 'view',
      disabled: !checkPermission('getPlaylist') || !checkFeatures('playlist'),
    },
    {
      name: t('delete'),
      url: '',
      function: getDeletePlaylistId,
      key: 'delete',
      disabled: !checkPermission('deletePlaylist') || !checkFeatures('playlist'),
    },
  ];

  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('playlistName'),
      accessorKey: 'name',
    },
    {
      header: t('totalContents'),
      accessorKey: 'count',
      muiFilterTextFieldProps: { type: 'number', onWheel: (e) => e.target.blur() },
      filterFn: 'commonFIlterFn',
      enableSorting: false,
      Cell: ({ row }) => <PopupModal list={row.original.contents_list} name="contents list" />,
    },
    {
      header: t('author'),
      filterFn: 'commonFIlterFn',
      accessorKey: 'user',
      Cell: ({ row }) => row.original.author,
    },
    {
      header: t('createdDate'),
      accessorKey: 'createdAt',
      accessorFn: (originalRow) => new Date(originalRow.createdAt),
      filterVariant: 'date-range',
      enableColumnFilter: false,
      Cell: ({ row }) => row.original.createDate,
    },
    {
      header: t('fileSize'),
      accessorKey: 'totalFileSize',
      enableColumnFilter: false,
      Cell: ({ row }) => row.original.total_file_size,
    },
    {
      header: '',
      accessorKey: 'id',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 50,
      Cell: ({ row }) => (
        <DropDownMenu
          action={actions}
          icon={<MoreMenuIcon />}
          value={row?.original}
          dropdown="tableForm"
          tooltip=""
        />
      ),
    },
  ];

  const addPlaylist = () => {
    navigate('add');
  };

  const exportData = () => {
    const columnHeaders = ['name', 'contents', 'createdAt', 'author', 'file_size'];

    // Filter the hidden column
    const filteredHeaders = columnHeaders.filter((header) => {
      if (header === 'contents') {
        return columnVisibility.count !== false;
      }
      if (header === 'author') {
        return columnVisibility.user !== false;
      }
      if (header === 'file_size') {
        return columnVisibility.totalFileSize !== false;
      }
      return columnVisibility[header] !== false;
    });

    // API call for export CSV
    playlistApi.playlistExport({ fields: filteredHeaders, ...filter })
      .then((res) => {
        const results = [];
        res?.data?.forEach((item) => {
          if (item?.contents) {
            item?.contents?.forEach((content) => {
              results.push({
                name: item?.name,
                playlist_size: convertBytes(item?.file_size),
                content_name: content?.name,
                content_type: content?.file_type,
                content_size: convertBytes(content?.file_size),
                tags: content?.tags.join(', '),
                author: item?.author,
                createDate: changeDateTimeFormat(item?.createdAt),
              });
            });
          } else {
            results.push({
              name: item?.name,
              playlist_size: convertBytes(item?.file_size),
              author: item?.author,
              createDate: changeDateTimeFormat(item?.createdAt),
            });
          }
        });

        // Loop through each object in the array and remove undefined keys
        const updatedResults = results.map((obj) => {
          const newObj = {};
          Object.keys(obj).forEach((key) => {
            if (obj[key] !== undefined) {
              newObj[key] = obj[key];
            }
          });
          return newObj;
        });

        // Function to export as CSV file
        exportCSV(Object.keys(updatedResults?.[0]), updatedResults);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilter(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getPlaylists(1, filteredValues);
  };

  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(tableFilter).length === 0) {
      getPlaylists(1);
    }
  }, []);

  return (
    <div className="content">
      <div className="main-content">
        <TopContent
          label={t('playlist')}
          buttonClass="success-button playlist-createbutton"
          button={checkPermission('addPlaylist') && checkFeatures('playlist')}
          buttonLabel={t('newPlaylist')}
          click={addPlaylist}
        />
        <DeleteModal
          status={openDeleteModal}
          closeModal={setOpenDeleteModal}
          okFunction={deletePlaylistById}
          buttonLabel={t('delete')}
          title={t('areYouSureYouWantToDeleteThisPlaylist?')}
          subTitle=<span>{t('thisWillDeleteThisPlaylistPermanently')} <br />{t('youCannotUndoThisAction.')}</span>
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area playlist-table">
              <DataTable
                header={columns}
                value={playlist}
                onFilterOrSortingChange={onFilterOrSortingChange}
                status={status}
                totalPageCount={totalPageCount}
                reduxColumnFiltersSorting={tableFilter}
                pageCount={pageCount}
                scrollData={handleScroll}
                totalDataCount={totalDataCount}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
                height={height.onlyTableHeight}
                exportButton={checkFeatures('playlist') && checkPermission('exportPlaylist')}
                exportData={exportData}
                isFirstRender={isFirstRender}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
PlayList.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
      contents_list: PropTypes.arrayOf().isRequired,
    }).isRequired,
  }),
};
PlayList.defaultProps = {
  row: null,
};
export default PlayList;
