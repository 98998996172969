/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Paper, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow,
  Tabs,
  Tab,
  createTheme,
  ThemeProvider,
}
  from '@mui/material';
import { updateCurrentTemplate } from '../../../redux/slices/TemplateSlice';
import arrowLeftIcon from '../../../assets/icons/arrow-left.png';
import Button from '../../../shared/component/Button';
import {
  CategoryIcon,
  CategoryActiveIcon,
  HideIcon,
  SettingsActiveIcon,
  SettingsIcon,
  ShowIcon,
  TemplateEditIcon,
} from '../../../shared/component/svgIcon';
import { checkFeatures, checkPermission } from '../../../utils/helpers';
import StyleRenderPage from './StyleRenderPage';

function PosTemplateEditor(props) {
  const [data, setData] = useState([]);
  const [index, setIndex] = useState(0);
  const [showStyle, setShowStyle] = useState(false);
  const [disable, setDisable] = useState(false);
  const [submitDisable, setSubmitDisable] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const titleRef = useRef(null);
  const { id } = useParams();

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            overflow: 'visible',
            width: '100%',
            '&.Mui-selected': {
              color: '#212B36 !important',
            },
          },
        },
      },
    },
    MuiTableContainer: {
      root: {
        boxShadow: 'none',
        borderRadius: '0px',
      },
    },
  });

  let parsingLocalData;
  useEffect(() => {
    if (props?.templateData && Object.keys(props?.templateData).length > 0) {
      localStorage.setItem('templateEditData', JSON.stringify(props?.templateData));
    }
    const localData = localStorage.getItem('templateEditData');

    if (localData) {
      parsingLocalData = JSON.parse(localData);

      if (Object.keys(props?.templateData).length === 0) {
        setData([parsingLocalData]);
      } else {
        setData([{ ...props?.templateData }]);
      }
    } else if (props?.templateData && Object.keys(props?.templateData).length > 0) {
      setData([{ ...props?.templateData }]);
    }

    if (!checkPermission('getTemplatesList') || !checkFeatures('templates')) {
      setDisable(true);
    }
    const unloadCallback = (event) => {
      event.preventDefault();
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  useEffect(() => () => localStorage.removeItem('templateEditData'), []);

  const handleChangeTitle = (dataIndex, event) => {
    if (event.target.value.length <= 20 && event.target.value.length > 0) {
      const newData = JSON.parse(JSON.stringify(data));
      newData[dataIndex].template_title = event.target.value;
      setData(newData);
    }
  };

  const onHideCategory = (value) => {
    // setHideCategory((prev) => !prev);
    const newData = JSON.parse(JSON.stringify(data)); // Deep copy the data
    const newItems = [...(newData?.[0]?.template_contents.items ?? [])]; // Copy the items array
    if (newItems[index]) {
      if (value === 'hide') {
        newItems[index].categoryHide = true; // Add the hide property
        setSubmitDisable('');
      } else if (value === 'show') {
        newItems[index].categoryHide = false; // Add the hide property
      }
      newData[0].template_contents.items = newItems; // Set the updated items array back to the data
      setData(newData); // Update the state with the new data
    }
  };

  const onHideItem = (value, itemIndex) => {
    const newData = JSON.parse(JSON.stringify(data)); // Deep copy the data
    const newItems = newData?.[0]?.template_contents.items?.[index]; // Copy the items array
    if (newItems?.products[itemIndex]) {
      // Toggle the hide property based on the value
      if (value === 'hide') {
        newItems.products[itemIndex].hide = true;
      } else if (value === 'show') {
        newItems.products[itemIndex].hide = false;
      }
      // Update the state with the new data
      setData(newData);
    }
  };

  const handleCategoryClick = (catIndex) => {
    setIndex(catIndex);
  };
  const handleSaveTemplate = () => {
    dispatch(updateCurrentTemplate(...data));
    navigate(`/admin/templates/view-html/${id}`);
  };

  const handleStyleSettings = () => {
    setShowStyle(true);
    dispatch(updateCurrentTemplate(...data));
  };
  const handleCategorySettings = () => {
    dispatch(updateCurrentTemplate(...data));
    setShowStyle(false);
  };
  const handleArrowClick = () => {
    const confirmChange = window.confirm('Are you sure? Changes may not be saved.');
    if (confirmChange) {
      dispatch(updateCurrentTemplate(...data));
      navigate('/admin/integration');
    }
  };
  useEffect(() => {
    if (data?.[0]?.template_name) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [data?.[0]?.template_name]);
  return (
    <div>
      {data?.map((ele, eleIndex) => (
        <div className="pos-template-editor-table" key={ele?._id}>
          <div className="top-nav">
            <div className="header-title-wrapper">
              <div
                style={{
                  width: '24px',
                  height: '24px',
                  cursor: 'pointer',
                }}
                onClick={() => handleArrowClick()}
                role="presentation"
              >
                <img
                  src={arrowLeftIcon}
                  alt="left-arrow"
                />
              </div>
            </div>

            <div className="right-side">
              <div className="wrapper">
                <div className="header-button">
                  <Button
                    label={t('preview')}
                    click={handleSaveTemplate}
                    disabled={disable}
                    classes="success-button"
                  />
                </div>
              </div>
              {submitDisable
                && (
                  <div className="error-text">
                    <span className="text-danger">
                      {t('thistemplateCanShowOnly')} {submitDisable} {t('categoryPleasehideOthers')}
                    </span>
                  </div>
                )}
            </div>
          </div>
          <div className="menu-body">
            <div className="side-menu">
              <div
                className={showStyle ? 'side-menu-title' : 'side-menu-title active'}
                onClick={handleCategorySettings}
                role="presentation"
              >
                {showStyle ? (
                  <CategoryIcon />
                ) : (
                  <CategoryActiveIcon />
                )}
                <h5>{t('category')}</h5>
              </div>
              <div
                className={!showStyle ? 'side-menu-title' : 'side-menu-title active'}
                onClick={handleStyleSettings}
                role="presentation"
              >
                {!showStyle ? (
                  <SettingsIcon />
                ) : (
                  <SettingsActiveIcon />
                )}
                <h5>{t('settings')}</h5>
              </div>

            </div>
            {!showStyle
              && (
                <div className="category-table">
                  <div className="template-main-title">
                    <div className="title-form">
                      <input
                        name="title"
                        className="title-input"
                        value={ele?.template_title}
                        onChange={(event) => handleChangeTitle(eleIndex, event)}
                        placeholder="title"
                        id="title"
                        autoComplete="off"
                        ref={titleRef}
                      />
                      <div className="edit-icon" onClick={() => titleRef.current.focus()} role="none">
                        <TemplateEditIcon />
                      </div>
                    </div>
                    {ele?.template_title?.length >= 20
                      && (<span className="text-danger">{t('titleShouldHaveMaxOf20LettersAndMinOf1Letter')}</span>)}
                  </div>
                  <div className="tabs-container">
                    <ThemeProvider theme={theme}>
                      <Tabs
                        value={index}
                        onChange={(event, newValue) => handleCategoryClick(newValue)}
                        aria-label="secondary tabs example"
                        sx={{
                          '& .MuiTab-root': {
                            textTransform: 'capitalize',
                            fontSize: '14px',
                          },
                          '& .Mui-selected': {
                            color: '#212B36 !important',
                          },
                          '& .MuiTabs-indicator': {
                            backgroundColor: '#212B36',
                          },
                        }}
                      >
                        {ele?.template_contents?.items?.map((val, catIndex) => (
                          <Tab value={catIndex} label={val?.category} />
                        ))}
                      </Tabs>
                    </ThemeProvider>
                  </div>
                  <div className="categories">
                    {!ele?.template_contents?.items?.[index]?.categoryHide ? (
                      <div className="inner-category" onClick={() => onHideCategory('hide')} role="presentation">
                        <HideIcon />
                        <span>{t('hidecategory')}</span>
                      </div>
                    ) : (
                      <div className="inner-category" onClick={() => onHideCategory('show')} role="presentation">
                        <ShowIcon />
                        <span>{t('showcategory')}</span>
                      </div>
                    )}
                  </div>
                  <TableContainer
                    component={Paper}
                    style={{ borderBottom: '0.5px solid #CED0D3', boxShadow: 'inherit', borderRadius: '0' }}
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ color: '#9b9b9b' }}>{t('productImage')}</TableCell>
                          <TableCell style={{ color: '#9b9b9b' }}>{t('productName')}</TableCell>
                          <TableCell style={{ color: '#9b9b9b' }}>{t('productId')}</TableCell>
                          <TableCell style={{ color: '#9b9b9b' }}>{t('description')}</TableCell>
                          <TableCell style={{ color: '#9b9b9b' }}>{t('offerprice')}</TableCell>
                          <TableCell style={{ color: '#9b9b9b' }}>{t('productprice')}</TableCell>
                          <TableCell style={{ color: '#9b9b9b' }}>{t('actions')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ele?.template_contents?.items?.[index]?.products.map((item, itemIndex) => (
                          <TableRow
                            key={item?.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            {!ele?.template_contents?.items?.[index]?.categoryHide
                              && (
                                <>
                                  <TableCell width={200}>
                                    <div className="product-image">
                                      <img src={item?.product_image} alt="" />
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    {item?.name}
                                  </TableCell>
                                  <TableCell>{item?.product_id}</TableCell>
                                  <TableCell>
                                    {item?.description}
                                  </TableCell>
                                  <TableCell>
                                    {item?.offer_price}
                                  </TableCell>
                                  <TableCell>
                                    {item?.price}
                                  </TableCell>
                                  <TableCell width={200}>
                                    <div className="action-column">
                                      {!item?.hide ? (
                                        <div
                                          className="action-buttons"
                                          onClick={() => onHideItem('hide', itemIndex)}
                                          role="presentation"
                                        >
                                          <HideIcon />
                                          <span>{t('hide')}</span>
                                        </div>
                                      ) : (
                                        <div
                                          className="action-buttons"
                                          onClick={() => onHideItem('show', itemIndex)}
                                          role="presentation"
                                        >
                                          <ShowIcon />
                                          <span>{t('show')}</span>
                                        </div>
                                      )}
                                    </div>
                                  </TableCell>
                                </>
                              )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            {showStyle && <StyleRenderPage setData={setData} />}
          </div>
        </div>
      ))};
    </div>
  );
}

PosTemplateEditor.propTypes = {
  templateData: PropTypes.arrayOf.isRequired,
};

export default PosTemplateEditor;
