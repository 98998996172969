/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import dashboard from '../../utils/api/dashboard';
import { setErrorNotification } from './NotificationSlice';
import { changeDateTimeFormat, fetchColor } from '../../utils/helpers';

export const fetchDisplayStatus = createAsyncThunk(
  'displayStatus/fetchDisplayStatus',
  async (data, { dispatch }) => {
    const response = await dashboard.getDisplayStatus(data)
      .then((res) => {
        const pieChartData = res.data.map((val) => ({
          name: val._id,
          value: val.count,
          color: fetchColor(val._id),
        }));
        return {
          ...res?.data,
          results: pieChartData,
          code: 200,
        };
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
    return response;
  },
);

export const fetchDisplayOs = createAsyncThunk(
  'displayOs/fetchDisplayOs',
  async (data, { dispatch }) => {
    const responseData = await dashboard.getDisplayOs(data)
      .then((res) => {
        const displayOsType = res.data.map((val) => ({
          name: val._id ?? '',
          value: val.count,
          color: fetchColor(val._id),
        }));
        displayOsType.sort((a, b) => a.value - b.value);

        return {
          ...res,
          results: displayOsType,
          code: 200,
        };
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
    return responseData;
  },
);

export const fetchContentSchedules = createAsyncThunk(
  'ContentSchedules/fetchContentSchedules',
  async (data, { dispatch }) => {
    const response1 = await dashboard.getContentSchedules(data).then((res) => {
      const contentsData = res.data.map((val) => ({
        ...val,
        createdAt: moment(val.createdAt).format('YYYY-MM-DD HH:mm a'),
      }));
      contentsData.sort((a, b) => b.play_count - a.play_count);
      return {
        results: contentsData,
        code: 200,
      };
    })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
    return response1;
  },
);

// export const fetchStorageStats = createAsyncThunk(
//   'StorageStats/fetchStorageStats',
//   async (data, { dispatch }) => {
//     const response2 = await dashboard.getStorageStats(data).then((res) => {
//       const storageStats = res?.data?.storage?.map((val) => ({
//         ...val,
//         label: val._id,
//         value: val.file_count,
//         file_size: Math.floor(val.total < 1 ? val.total * 1024 : val.total),
//         type: val.total < 1 ? 'MB' : 'GB',
//       }));
//       return {
//         ...res?.data,
//         storageStats,
//         code: 200,
//       };
//     })
//       .catch((error) => {
//         dispatch(setErrorNotification(error?.response?.data));
//       });
//     return response2;
//   },
// );

export const fetchScheduleSyncStatus = createAsyncThunk(
  'ScheduleSyncStatus/fetchScheduleSyncStatus',
  async (data, { dispatch }) => {
    const response = await dashboard.getScheduleSyncStatus(data)
      .then((res) => {
        const scheduleStatus = res?.data;
        const chartData = [
          {
            name: 'Synced',
            value: Number((scheduleStatus?.syncPercentage || 0)?.toFixed(2)),
            color: '#0A7BCC',
          },
          {
            name: 'Non Synced',
            value: Number((scheduleStatus?.nonSyncPercentage || 0)?.toFixed(2)),
            color: '#EAEAEA',
          },
        ];
        return {
          results: chartData,
          code: 200,
        };
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
    return response;
  },
);

export const fetchRecentActivities = createAsyncThunk(
  'RecentActivity/fetchRecentActivities',
  async (data, { dispatch }) => {
    const response = await dashboard.getRecentActivities(data)
      .then((res) => {
        const recentActivityData = res?.data?.map((value) => ({
          ...value,
          updatedAt: changeDateTimeFormat(value.updatedAt),
        }));
        return {
          ...res?.data,
          results: recentActivityData,
          code: 200,
        };
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
    return response;
  },
);

const initialState = {
  displayStatusData: [],
  displayOsData: [],
  storageStatsData: [],
  limitedStorage: '0',
  contentData: [],
  status: 'succeeded',
  result: {},
  error: {},
  scheduleSyncStatusData: [],
  recentActivityData: [],
};

export const DashBoardSlice = createSlice({
  name: 'DashBoard',
  initialState,
  reducers: {
    showErrorDisplayStatus: (state, action) => {
      state.error = action.payload;
    },
    resetDashboardState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDisplayStatus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDisplayStatus.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action?.payload?.code === 200) {
          state.displayStatusData = action?.payload?.results;
        }
      })
      .addCase(fetchDisplayStatus.rejected, (state) => {
        state.status = 'failed';
      });

    builder
      .addCase(fetchDisplayOs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDisplayOs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action?.payload?.code === 200) {
          state.displayOsData = action?.payload?.results;
        }
      })
      .addCase(fetchDisplayOs.rejected, (state) => {
        state.status = 'failed';
      });

    builder
      .addCase(fetchContentSchedules.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchContentSchedules.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action?.payload?.code === 200) {
          state.contentData = action?.payload?.results;
        }
      })
      .addCase(fetchContentSchedules.rejected, (state) => {
        state.status = 'failed';
      });

    // builder
    //   .addCase(fetchStorageStats.pending, (state) => {
    //     state.status = 'loading';
    //   })
    //   .addCase(fetchStorageStats.fulfilled, (state, action) => {
    //     state.status = 'succeeded';
    //     if (action?.payload?.code === 200) {
    //       state.storageStatsData = action?.payload?.storageStats;
    //       state.limitedStorage = action?.payload?.companySubscriptionStorageLimit;
    //     }
    //   })
    //   .addCase(fetchStorageStats.rejected, (state) => {
    //     state.status = 'failed';
    //   });

    builder
      .addCase(fetchScheduleSyncStatus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchScheduleSyncStatus.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) {
          state.scheduleSyncStatusData = action?.payload?.results;
        }
      })
      .addCase(fetchScheduleSyncStatus.rejected, (state) => {
        state.status = 'failed';
      });

    builder
      .addCase(fetchRecentActivities.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRecentActivities.fulfilled, (state, action) => {
        if (action?.payload?.code === 200) {
          state.recentActivityData = action?.payload?.results;
        }
        state.status = 'succeeded';
      })
      .addCase(fetchRecentActivities.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  showErrorDisplayStatus,
  resetDashboardState,
} = DashBoardSlice.actions;
export default DashBoardSlice.reducer;
