import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator';

import DataTable from '../Table';

import TopContent from '../../shared/component/TopContent';
import ModalBox from '../../shared/component/ModalBox';
import NewContent from './components/NewContent';
import ContentUpload from './components/ContentUpload';
import DropDownMenu from '../../shared/component/DropDownMenu';

import {
  checkFeatures,
  checkPermission,
  convertBytes,
  fileNameWithoutExtension,
  fileTypeValidation,
  // uploadImageResize,
} from '../../utils/helpers';

import myContent from '../../utils/api/my-content';

import youtubeIcon from '../../assets/images/youtube-icon.png';
import urlIcon from '../../assets/images/url-image.png';
import pdfIcon from '../../assets/images/pdf-icon.png';

import loginImage from '../../assets/images/loginPage.jpg';
import DeleteModal from '../../shared/component/DeleteModal';
import GridListView from '../Libraries/component/GridListView';

import {
  fetchContentList,
  setFilter,
  deleteContent,
  setTableFilter,
} from '../../redux/slices/MyContentSlice';

import { setErrorNotification } from '../../redux/slices/NotificationSlice';
import { fetchPlaylistList } from '../../redux/slices/PlaylistSlice';
import ContentStatus from './components/ContentStatus';
import DownloadContent from './components/DownloadContent';
import ContentView from './components/ContentView';
import exportCSV from '../Global/exportCSV';
import {
  MoreMenuIcon,
  ListMenuIcon,
  ActiveListMenu,
  GridViewIcon,
  ActiveGridViewIcon,
} from '../../shared/component/svgIcon';
import height from '../../utils/size-variables';
import ContentEdit from './components/ContentEdit';
import TemplateEdit from './components/TemplateEdit';
import MultipleFileUpload from './components/MultiFleUpload';

function MyContent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page } = useParams();
  const [isFirstRender, setIsFirstRender] = useState(true);

  const {
    contents,
    pageCount,
    totalPageCount,
    status,
    filter,
    tableFilter,
    totalDataCount,
  } = useSelector((state) => state.myContent);

  const [storage] = useState({
    imageSize: '',
    imageCount: '',
    videoSize: '',
    videoCount: '',
    totalSize: 0,
    totalGB: '',
  });
  const [contentId, setContentId] = useState('');
  const [createContentModal, setCreateContentModal] = useState({
    title: '',
    content: 0,
  });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [uploadingContent, setUploadingContent] = useState(false);

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({
    orientation: false, // Set orientation column hidden by Default
  });

  const tabPages = [
    {
      key: 'list',
      icon: <ListMenuIcon />,
      activeIcon: <ActiveListMenu />,
      enable: checkPermission('getContent'),
      tooltip: t('list'),
    },
    {
      key: 'grid',
      icon: <GridViewIcon />,
      activeIcon: <ActiveGridViewIcon />,
      enable: checkPermission('getContent'),
      tooltip: t('grid'),
    },
  ];

  // Function to handle get content list
  const getContents = (counts, filters = {}) => {
    if (status === 'succeeded') {
      dispatch(fetchContentList({ page: counts, limit: 20, ...filters }));
    }
  };

  // Fetch the total & available storage of the company
  // const getTotalContentSize = () => {
  //   myContent.getTotalContentSize().then((response) => {
  //     setStorage({
  //       ...storage,
  //       totalSize: response?.data?.companyUsedStorage || 0,
  //       totalGB: response?.data?.companySubscriptionStorageLimit || 0,
  //       imageSize: '',
  //       imageCount: '',
  //       videoSize: '',
  //       videoCount: '',
  //     });
  //   }).catch((error) => {
  //     dispatch(setErrorNotification(error?.response ? error?.response?.data : error));
  //   });
  // };

  // Delete the content by Content ID
  const deleteContentById = () => {
    myContent.deleteContent(contentId).then(() => {
      dispatch(deleteContent(contentId));
      setOpenDeleteModal(false);
      dispatch(fetchPlaylistList({ page: 1 }));
      setContentId('');
      // getTotalContentSize();
    }).catch((error) => {
      dispatch(setErrorNotification(error?.response?.data));
    });
  };

  // Get next page data on Scroll
  const handleScroll = () => {
    getContents(pageCount + 1, filter);
  };

  // List view - Grid view toggle
  const changePageView = (url) => {
    navigate(`../list/${url}`);
  };

  // Confirmation popup for delete content
  const getDeleteContentId = (id) => {
    setContentId(id); // Set the ID to be delete
    setOpenDeleteModal(true);
  };

  // Open the view content popup
  const viewContent = (singleContent) => {
    setCreateContentModal({
      title: t('preview'),
      content: <ContentView singleContent={singleContent} />,
    });
  };

  const refresh = () => {
    // getTotalContentSize();
  };

  // Function to handle close modal only when is not uploading
  const closeModal = () => {
    if (!uploadingContent) {
      setCreateContentModal({
        title: '',
        content: 0,
      });
    }
  };

  // Function to handle edit content
  const editContent = (id) => {
    const data = contents.find((e) => e.id === id);
    if (data?.template_content && data?.template_content?.length > 0) {
      setCreateContentModal({
        title: t('selectToSwitchTemplate'),
        content: <TemplateEdit
          close={closeModal}
          contentData={data}
          setCreateContent={setCreateContentModal}
        />,
      });
    } else {
      setCreateContentModal({
        title: t('uploadMedia'),
        content: <ContentEdit
          contentData={data}
          close={closeModal}
          refresh={refresh}
        />,
      });
    }
  };

  // Function to handle download content
  const downloadContent = async (id) => {
    const content = contents?.find((item) => item?.id === id) || {};
    if (content.signedUrl) {
      const response = await DownloadContent(content);
      if (response) {
        dispatch(setErrorNotification({ message: 'Something went wrong! Please try again' }));
      }
    } else {
      dispatch(setErrorNotification({ message: `File name "${content?.name}" was not found` }));
    }
  };

  const statusCheck = (id, itemStatus) => {
    let isDisabled = false;
    if (id === 'download-content') {
      isDisabled = itemStatus?.includes('url')
        || itemStatus?.includes('youtube')
        || itemStatus?.includes('template')
        || itemStatus === 'PosItEasy';
    }
    if (id === 'edit-content') {
      isDisabled = itemStatus?.includes('template');
    }
    return isDisabled;
  };

  // Content dropDown actions
  const actions = [
    {
      name: t('view'),
      url: '',
      function: viewContent,
      id: 'view-content',
      disabled: !checkPermission('getContent') || !checkFeatures('content'),
      sendFullDetails: true,
    },
    {
      name: t('edit'),
      url: '',
      function: editContent,
      id: 'edit-content',
      // Check both roles permission and feature available for edit the content
      disabled: !checkPermission('editContent') || !checkFeatures('content'),
    },
    {
      name: t('download'),
      url: '',
      function: downloadContent,
      id: 'download-content',
      disabled: !checkPermission('downloadContent') || !checkFeatures('content'),
    },
    {
      name: t('delete'),
      url: '',
      function: getDeleteContentId,
      id: 'delete-content',
      disabled: !checkPermission('deleteContent') || !checkFeatures('content'),
    },
  ];

  // Content table headers
  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('contentName'),
      accessorKey: 'name',
      Cell: ({ row }) => (
        <div className="content-thumbnail">
          {
            (() => {
              let thumbnailImage;
              if (row.original?.file_type === 'youtube') {
                thumbnailImage = (
                  <img
                    alt="Youtube"
                    src={youtubeIcon}
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                    className="default-image"
                  />
                );
              } else if (row.original?.file_type === 'url') {
                thumbnailImage = (
                  <img
                    alt="Url"
                    src={urlIcon}
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                    className="default-image"
                  />
                );
              } else if (row.original?.file_type?.includes('pdf')) {
                thumbnailImage = (
                  <img
                    alt="Url"
                    src={pdfIcon}
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                    className="default-image"
                  />
                );
              } else {
                thumbnailImage = (
                  <img
                    alt="thumbnail"
                    src={
                      row.original?.thumbnailSignedUrls?.px_50x50
                      || row.original?.thumbnail?.px_50x50
                      || loginImage
                    }
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                  />
                );
              }
              return thumbnailImage;
            })()
          }
          <span className="content_name">
            {row.original.name}
          </span>
        </div>
      ),
    },
    {
      header: t('mediaType'),
      accessorKey: 'file_type',
      enableSorting: false,
      Cell: ({ row }) => row.original?.file_type_to_show,
    },
    {
      header: t('orientation'),
      accessorKey: 'orientation',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('author'),
      accessorKey: 'author',
    },
    {
      header: t('createdDate'),
      accessorKey: 'createdAt',
      accessorFn: (originalRow) => new Date(originalRow?.createdAt),
      filterVariant: 'date-range',
      enableColumnFilter: false,
      Cell: ({ row }) => row.original?.createDate,
    },
    {
      header: t('fileSize'),
      accessorKey: 'file_size',
      enableColumnFilter: false,
    },
    // {
    //   header: t('durationSec'),
    //   accessorKey: 'duration',
    //   enableColumnFilter: false,
    //   Cell: ({ row }) => `${row.original.duration}s`,
    // },
    {
      header: '',
      accessorKey: 'id',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 50,
      Cell: ({ row }) => (
        <DropDownMenu
          action={actions}
          icon={<MoreMenuIcon />}
          value={row?.original}
          dropdown="tableForm"
          tooltip=""
          statusCheck={statusCheck}
        />
      ),
    },
  ];

  // Generate a single thumbnail thumbnail
  const generateSingleThumbnail = async (videoFile) => {
    try {
      const thumbnailArray = await generateVideoThumbnails(videoFile, 1);
      return thumbnailArray?.[0];
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const selectMultipleContent = (filesWithThumbnail) => {
    setCreateContentModal({
      title: t('uploadMedia'),
      content: <ContentUpload
        contentData={{
          tags: [],
          duration: 5,
          filesWithThumbnail,
          file_type: 'multi_file',
        }}
        close={closeModal}
        refresh={refresh}
        setUploadingContent={setUploadingContent}
      />,
    });
  };

  // When user selects the image/video then change the popup view
  const selectMedia = async (files) => {
    const filesArray = Array.from(files);
    const filesWithThumbnail = await Promise.all(
      filesArray.map(async (file) => ({
        file,
        thumbnail: file?.type?.includes('video') ? await generateSingleThumbnail(file) : null,
        content_name: fileNameWithoutExtension(file?.name || ''),
      })),
    );

    let checkFileValid = '';

    filesArray.some((file) => {
      const fileType = file?.type === 'application/pdf' ? 'application/pdf' : file.type.split('/')[0];
      checkFileValid = fileTypeValidation(file, fileType);
      return checkFileValid !== ''; // Break the loop if the condition is met
    });
    if (checkFileValid === '') {
      setCreateContentModal({
        title: 'Add Content', // add transition
        content: <MultipleFileUpload
          selectedFiles={filesWithThumbnail}
          selectMultipleContent={selectMultipleContent}
          closeModal={closeModal}
        />,
      });
    } else {
      dispatch(setErrorNotification({ message: checkFileValid }));
    }
  };

  // When user select add Url button then open model view
  const selectURL = (type) => {
    setCreateContentModal({
      title: t('addUrl'),
      content: <ContentUpload
        contentData={{
          tags: [],
          file_type: type,
        }}
        close={closeModal}
        refresh={refresh}
        setUploadingContent={setUploadingContent}
      />,
    });
  };

  // When a user click on New-content button open the Upload media popup
  const openMediaUploadModal = () => {
    setCreateContentModal({
      title: t('newContent'),
      content: <NewContent
        selectMedia={selectMedia}
        selectURL={selectURL}
      />,
    });
  };

  // Function to handle export CSV file
  const exportData = () => {
    const columnHeaders = ['name', 'file_size', 'createdAt', 'author', 'orientation', 'file_type'];

    // Filter the hidden column
    const filteredHeaders = columnHeaders.filter((header) => columnVisibility[header] !== false);

    // API call for export CSV
    myContent.myContentExport({ fields: filteredHeaders, ...filter })
      .then((res) => {
        const rows = res?.data?.map((row) => ({
          ...row,
          file_size: convertBytes(row?.file_size), // Convert Bytes in KB, MB, GB
        }));
        // Function to export as CSV file
        exportCSV(filteredHeaders, rows);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilter(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getContents(1, filteredValues);
  };

  // Get storage stats of a company when the page loads
  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(tableFilter).length === 0) {
      getContents(1);
    }
    // getTotalContentSize();
  }, []);
  return (
    <div className="content">
      <div className="main-content">
        <TopContent
          label={t('myContent')}
          buttonClass="success-button myContent-createButton"
          button={checkPermission('addContent') && checkFeatures('content')}
          buttonLabel={t('newContent')}
          click={openMediaUploadModal}
        />
        <ModalBox
          status={createContentModal.content !== 0}
          closeModal={!uploadingContent ? closeModal : () => { }}
          modalView={createContentModal}
          notification={false}
        />
        <DeleteModal
          status={openDeleteModal}
          closeModal={setOpenDeleteModal}
          okFunction={deleteContentById}
          buttonLabel={t('delete')}
          title={t('areYouSureYouWantToDeleteThisContent?')}
          subTitle=<span>{t('thisWillDeleteThisContentPermanently')} <br />{t('youCannotUndoThisAction.')}</span>
        />
        <div className="content-area">
          <div className="my-content">
            {page === 'grid' && (
              <div className="tab-bar" style={{ alignItems: 'center' }}>
                <ContentStatus {...storage} pageViewChange={changePageView} tabPages={tabPages} page={page} />
              </div>
            )}
            <div className="my-content-area mydata-table">
              {
                page === 'list'
                  ? (
                    <DataTable
                      className="my-data-table"
                      reduxColumnFiltersSorting={tableFilter}
                      onFilterOrSortingChange={onFilterOrSortingChange}
                      header={columns}
                      value={contents}
                      status={status}
                      totalPageCount={totalPageCount}
                      pageCount={pageCount}
                      scrollData={handleScroll}
                      totalDataCount={totalDataCount}
                      rowSelection={rowSelection}
                      setRowSelection={setRowSelection}
                      columnVisibility={columnVisibility}
                      setColumnVisibility={setColumnVisibility}
                      height={height.tableHeight}
                      exportButton={checkFeatures('content') && checkPermission('exportContent')}
                      exportData={exportData}
                      isFirstRender={isFirstRender}
                      showToolbarLeft
                      toolbarLeft={
                        (<ContentStatus {...storage} pageViewChange={changePageView} tabPages={tabPages} page={page} />)
                      }
                    />
                  )
                  : (
                    <InfiniteScroll
                      dataLength={contents.length}
                      next={handleScroll}
                      height={height.cardsHeight}
                      hasMore={pageCount < totalPageCount}
                      loader={status === 'loading' && (
                        <h4 className="text-center">
                          <Box sx={{ display: 'flex' }}>
                            <CircularProgress color="success" />
                          </Box>
                        </h4>
                      )}
                    >
                      <GridListView value={contents} action={actions} />
                    </InfiniteScroll>
                  )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
MyContent.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
      file_type: PropTypes.string.isRequired,
      thumbnailSignedUrls: PropTypes.shape({
        px_50x50: PropTypes.string.isRequired,
      }),
      thumbnail: PropTypes.shape({
        px_50x50: PropTypes.string.isRequired,
      }),
      file_type_to_show: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      createDate: PropTypes.string.isRequired,
      file_size: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  }),
  contents: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
  pageCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  status: PropTypes.string,
  filter: PropTypes.objectOf(PropTypes.string),
  tableFilter: PropTypes.objectOf(PropTypes.string),
  totalDataCount: PropTypes.number,
};
MyContent.defaultProps = {
  row: null,
  contents: [],
  pageCount: 0,
  totalPageCount: 0,
  status: '',
  filter: {},
  tableFilter: {},
  totalDataCount: 0,
};

export default MyContent;
