import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import Slide from '@mui/material/Slide';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import startIcon from '../../../assets/icons/computer.png';
import { Input1 } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import auth from '../../../utils/api/auth';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { setLocalStorageItem } from '../../../utils/helpers';

function LicenseValidation(props) {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [disable, setDisable] = useState(true);
  const [open, setOpen] = useState(false);
  const [stage, setStage] = useState(1);
  const [activeKey, setActiveKey] = useState();

  const handleTooltipToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleChange = (name, changeValue) => {
    setActiveKey((prev) => ({
      ...prev,
      [name]: changeValue,
    }));
  };
  const submit = (type) => {
    if (type === 'licenseActive') {
      const licenseKey = activeKey.licenseKey.trim();
      if (!uploading) {
        setUploading(true);
        auth.licenseKeyActivation({ license: licenseKey })
          .then((res) => {
            setLocalStorageItem('licenseDetails', res?.data);
            if (res.data.companyDetails) {
              setDisable(true);
              setUploading(false);
              setStage(2);
            } else {
              setUploading(false);
              dispatch(setErrorNotification(res.data));
            }
          })
          .catch((error) => {
            setUploading(false);
            dispatch(setErrorNotification(error?.response?.data));
          });
      }
    } else if (type === 'continue') {
      props?.formStage(3);
    }
  };
  useEffect(() => {
    if (activeKey?.licenseKey) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [activeKey]);
  return (
    <div className="license-activation-main-container">
      {stage === 1 && (
        <div className="license-activation-container">
          <div className="activation-form">
            <div className="activation-header">
              <h3>Activate Your License!</h3>
              <div className="activation-sub-header">
                <span>Enter your license key below to unlock full</span>
                <span>access to the application</span>
              </div>
            </div>
            <div className="activation-input">
              <Input1
                label="License Key"
                type="text"
                name="licenseKey"
                placeholder="xxxx-xxxx-xxxx-xxxx"
                value={activeKey?.licenseKey}
                change={handleChange}
              />
              <div>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div>
                    <Tooltip
                      title={(
                        <Box sx={{ padding: '10px', maxWidth: '250px' }}>
                          <Typography variant="h6" gutterBottom style={{ fontSize: '14px' }}>
                            Where do I find my license key?
                          </Typography>
                          <ul
                            className="tooltip-list"
                            style={{
                              paddingLeft: '10px',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '5px',
                            }}
                          >
                            <li>
                              After purchasing the license, your key was sent to the email
                              address associated with your account.
                            </li>
                            <li>
                              Look for an email from Wejha Smart Signage with the subject
                              line: &quot;Your License Key for Wejha.&quot;

                            </li>
                            <li>
                              The license key is typically a string of letters and numbers
                              (e.g., XXXX-XXXX-XXXX-XXXX).
                            </li>
                            <li>
                              If you cannot find the email, please check your spam or junk
                              folder.
                            </li>
                          </ul>
                        </Box>
                      )}
                      open={open}
                      onClose={handleTooltipClose}
                      placement="top"
                      arrow
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      TransitionComponent={Slide}
                      TransitionProps={{
                        direction: 'left',
                        timeout: { enter: 300, exit: 400 },
                      }}
                    >
                      <div className="activation-tooltip" onClick={handleTooltipToggle} role="presentation">
                        <span>Where do I find my license key?</span>
                      </div>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              </div>
            </div>
            <div className="activation-btn">
              <Button
                label="Activate"
                click={() => submit('licenseActive')}
                classes={uploading ? 'success-button loading-btn' : 'success-button'}
                loading={uploading}
                disabled={disable}
              />
            </div>
          </div>
        </div>
      )}
      {stage === 2 && (
        <div className="start-application-container">
          <div className="start-few-step-container">
            <div className="container-header">
              <h3>Welcome to Wejha!!</h3>
              <div className="sub-content">
                <span>Your journey to seamless and powerful Digital Signage </span>
                <span> Management starts here!</span>
              </div>
            </div>
            <div className="main-content">
              <div className="start-image"><img src={startIcon} alt="icon" /></div>
              <div className="few-step-content">Start using application in just a few stpes.</div>
            </div>
            <div className="start-continue-btn">
              <Button
                label="Continue"
                classes="success-button"
                click={() => submit('continue')}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

LicenseValidation.propTypes = {
  formStage: PropTypes.func.isRequired,
};

export default LicenseValidation;
