import React from 'react';
import PropTypes from 'prop-types';
import DotLoader from './Loader';
import { ArrowRight } from './svgIcon';

function Button(props) {
  return (
    <div className="btn">
      <button
        type="button"
        className={props?.classes}
        id={props?.id}
        onClick={props?.click}
        disabled={props?.disabled}
        style={{ cursor: props?.loading ? 'not-allowed' : 'pointer' }}
      >
        <span>{props?.label}</span>
        {props?.loading && (
          <DotLoader
            dotActiveColor="#FFFFFF"
            dotBlurColor="#FFFFFF4e"
          />
        )}
        {props?.arrow && (
          <ArrowRight />
        )}
      </button>
    </div>
  );
}

Button.propTypes = {
  classes: PropTypes.string.isRequired,
  click: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  id: PropTypes.string,
  arrow: PropTypes.bool,
};
Button.defaultProps = {
  disabled: false,
  loading: false,
  arrow: false,
  id: null,
};

export default Button;
