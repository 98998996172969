/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector, useDispatch } from 'react-redux';
import {
  CircularProgress,
  Box,
} from '@mui/material';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import saveApi from '../../../utils/api/widget';
import {
  fetchSavedWidget,
  setSingleSavedWidget,
  deleteSavedWidget,
  createWidgetStoreLocally,
  updateWidget,
} from '../../../redux/slices/WidgetTempSlice';
import { MoreMenuIconHorizontal } from '../../../shared/component/svgIcon';
import DeleteModal from '../../../shared/component/DeleteModal';
import { changeDateTimeFormat, checkPermission, checkFeatures } from '../../../utils/helpers';
import height from '../../../utils/size-variables';
import DropDown from '../../../shared/component/DropDown';

function SaveWidget() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    savedWidget,
    savedWidgetPageCount,
    savedWidgetTotalDataCount,
    status,
  } = useSelector((state) => state.widgets);
  const selectedTemp = useSelector((state) => state.widgets.selectedWidget);
  const [loading, setLoading] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentWidgetId, setCurrentWidgetId] = useState(null);
  // const open = Boolean(anchorEl);

  const getData = (data) => {
    if (status === 'succeeded') {
      dispatch(fetchSavedWidget(data)).then(() => setLoading(false));
    }
  };

  const editSavedLayoutName = (id, name) => {
    saveApi.updateWidget(id, { name })
      .then((res) => {
        dispatch(updateWidget(res?.data));
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };
  const handleDelete = () => {
    saveApi.deleteSavedWidget(currentWidgetId)
      .then(() => {
        dispatch(deleteSavedWidget(currentWidgetId));
        setOpenDeleteModal(false);
      }).catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setCurrentWidgetId(null);
  };

  const handleViewClick = (widgetDetails) => {
    // Implement view functionality
    if (widgetDetails.widget_id.name ? widgetDetails.widget_id.name === 'Clock' : selectedTemp.name === 'Clock') {
      // Redirect to clock edit page
      navigate(`/admin/apps/clock-template/edit-clock/${widgetDetails._id}`);
    } else if (widgetDetails?.widget_id?.name
      ? widgetDetails?.widget_id?.name === 'Weather'
      : selectedTemp.name === 'Weather'
    ) {
      // Redirect to weather edit page (adjust this based on your actual routing)
      navigate(`/admin/apps/weather-template/edit-weather/${widgetDetails._id}`);
    } else {
      // Default redirect behavior (for example, to edit form)
      navigate(`/admin/apps/rssfeed-template/edit-rssfeed/${widgetDetails._id}`);
    }
    dispatch(setSingleSavedWidget(widgetDetails));
  };

  const handleDeleteClick = (widgetDetails) => {
    setCurrentWidgetId(widgetDetails._id);
    setOpenDeleteModal(true);
  };

  // Content dropDown actions
  const actions = [
    {
      name: t('view'),
      url: '',
      function: handleViewClick,
      id: 'view-content',
      disabled: !checkPermission('editApps') || !checkFeatures('apps'),
      sendFullDetails: true,
    },
    {
      name: t('delete'),
      url: '',
      function: handleDeleteClick,
      id: 'delete-content',
      disabled: !checkPermission('deleteApps') || !checkFeatures('apps'),
      sendFullDetails: true,
    },
  ];

  const fetchData = () => {
    getData({ page: savedWidgetPageCount + 1 });
  };
  useEffect(() => {
    dispatch(createWidgetStoreLocally({}));
    if (savedWidget?.length <= 1) {
      getData({ page: 1 }); // Set loading to false after fetching widgets
    } else {
      setLoading(false);
    }
  }, [dispatch, savedWidget]);
  return (
    <div className="content-layout-container" style={{ flexWrap: 'wrap', gap: '50px' }}>
      <InfiniteScroll
        dataLength={savedWidget?.length}
        height={height.cardsHeight}
        next={fetchData}
        hasMore={savedWidget.length < savedWidgetTotalDataCount}
        loader={loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress color="success" />
          </Box>
        )}
      >
        <div className="widget-card">
          {
            savedWidget?.map((widget) => (
              <div className="widget-card-item" key={widget?._id}>
                {
                  widget?.rss_display_type === 'image_board' && widget?.preview_image === ''
                    ? (
                      <div className="card">
                        <div className="shimmerBG media"> </div>
                      </div>
                    )
                    : (
                      <img
                        src={widget?.preview_image}
                        alt={widget.name}
                        className="widget-image"
                      />
                    )
                }
                <div
                  className="avatar"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    className="wg-name"
                  >
                    {widget?.name}
                  </div>
                  <DropDown
                    displayEditIcon={checkFeatures('apps') && checkPermission('editApps')}
                    onClickEditIcon={editSavedLayoutName}
                    value={widget}
                    action={actions}
                    icon={<MoreMenuIconHorizontal />}
                  />
                </div>
                <p
                  className="timeformat"
                >
                  {changeDateTimeFormat(widget?.updatedAt)}
                </p>
              </div>
            ))
          }
        </div>
      </InfiniteScroll>
      <DeleteModal
        status={openDeleteModal}
        closeModal={handleCloseDeleteModal}
        okFunction={handleDelete}
        buttonLabel={t('delete')}
        title={t('areYouSureYouWantToDeleteThisContent?')}
        subTitle=<span>{t('thisWillDeleteThisContentPermanently')} <br />{t('youCannotUndoThisAction.')}</span>
      />
    </div>
  );
}

export default SaveWidget;
