import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { resetPreferenceState } from './AccountPreferenceSlice';
import { resetDashboardState } from './DashBoardSlice';
import { resetDeviceActiveLogsState } from './DeviceActiveLogsSlice';
import { resetDGDisplaySignageState } from './DGDisplaySignageSlice';
import { resetDisplayGroupState } from './DisplayGroupSlice';
import { resetDisplaySignageState } from './DisplaySignageSlice';
import { resetContentState } from './MyContentSlice';
import { resetNotification } from './NotificationSlice';
import { resetOtaUpdateState } from './OtaUpdateSlice';
import { resetPlaylistContentState } from './PlaylistContentSlice';
import { resetPlaylistState } from './PlaylistSlice';
import { resetPopState } from './POPScheduleSlice';
import { resetRentalState } from './RentalHistorySlice';
import { resetScheduleDisplayGroupState } from './ScheduleDisplayGroupSlice';
import { resetScheduleDisplaySignageState } from './ScheduleDisplaySignageSlice';
import { resetScheduleContentState } from './ScheduleMyContentSlice';
import { resetSchedulePlaylistState } from './SchedulePlaylistSlice';
import { resetScheduleState } from './ScheduleSlice';
import { resetSnapshotDisplayGroupState } from './SnapshotDisplayGroupSlice';
import { resetSnapshotDisplaySignageState } from './SnapshotDisplaySignageSlice';
import { resetSnapshotScheduleState } from './SnapshotScheduleSlice';
import { resetSnapshotState } from './SnapshotSlice';
import { resetSnapshotStoreLocationState } from './SnapshotStoreLocationSlice';
import { resetLocationState } from './StoreLocationSlice';
// import { resetSubscriptionState } from './SubscriptionSlice';
import { resetTemplateState } from './TemplateSlice';
import { resetUserState } from './UsersSlice';
import { resetRolesState } from './RolesSlice';
import { resetContentLayoutSlice } from './ContentLayoutSlice';
import { resetContentLayoutWidgets } from './ContentLayoutWidgetsSlice';
import { resetSavedContentLayoutSlice } from './SavedContentLayoutSlice';
import { resetScheduleLayoutContentState } from './ScheduleLayoutMyContentSlice';
import { resetScheduleLayoutPlaylistState } from './ScheduleLayoutPlaylistSlice';
import { resetWidgetsState } from './WidgetTempSlice';
import { resetStoreState } from './StoresSlice';

export const resetAllSlices = createAsyncThunk(
  'reset/resetAllSlices',
  async (_, { dispatch }) => {
    dispatch(resetPreferenceState());
    dispatch(resetDashboardState());
    dispatch(resetDeviceActiveLogsState());
    dispatch(resetDGDisplaySignageState());
    dispatch(resetDisplayGroupState());
    dispatch(resetDisplaySignageState());
    dispatch(resetContentState());
    dispatch(resetNotification());
    dispatch(resetOtaUpdateState());
    dispatch(resetPlaylistContentState());
    dispatch(resetPlaylistState());
    dispatch(resetPopState());
    dispatch(resetRentalState());
    dispatch(resetScheduleDisplayGroupState());
    dispatch(resetScheduleDisplaySignageState());
    dispatch(resetScheduleContentState());
    dispatch(resetSchedulePlaylistState());
    dispatch(resetScheduleState());
    dispatch(resetSnapshotDisplayGroupState());
    dispatch(resetSnapshotDisplaySignageState());
    dispatch(resetSnapshotScheduleState());
    dispatch(resetSnapshotState());
    dispatch(resetSnapshotStoreLocationState());
    dispatch(resetLocationState());
    // dispatch(resetSubscriptionState());
    dispatch(resetTemplateState());
    dispatch(resetUserState());
    dispatch(resetRolesState());
    dispatch(resetContentLayoutSlice());
    dispatch(resetContentLayoutWidgets());
    dispatch(resetSavedContentLayoutSlice());
    dispatch(resetScheduleLayoutContentState());
    dispatch(resetScheduleLayoutPlaylistState());
    dispatch(resetWidgetsState());
    dispatch(resetStoreState());
  },
);

const initialState = {};

const resetReduxStateSlice = createSlice({
  name: 'resetReduxState',
  initialState,
  reducers: {},
});

export default resetReduxStateSlice.reducer;
